import React, { ReactNode } from "react";
import { Button } from "react-bootstrap";

export function InlineIconButton({
  children,
  onClick,
  disabled,
  ariaLabel,
}: {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  ariaLabel: string;
}) {
  return (
    <Button
      style={{
        marginTop: "-6px",
      }}
      disabled={disabled}
      variant="link"
      aria-label={ariaLabel}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        // setTimeout(() => {
        //   (e.target as HTMLButtonElement).blur();
        // }, 0);
        (e.target as HTMLButtonElement).blur();
        // e.stopPropagation();
        // e.preventDefault();
        onClick();
      }}
    >
      {children}
    </Button>
  );
}

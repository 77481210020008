import { Breadcrumb as BSBreadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";

import { DynalistDocument, TreeNode } from "../../service/dynalist/document/DynalistDocument";
import { getAncestors } from "../../service/dynalist/nodes";
import { Routes } from "../../route";

export function Breadcrumb({ document, node }: { document: DynalistDocument; node: TreeNode }) {
  return (
    <BSBreadcrumb>
      {[...getAncestors(node), node].map((n) => (
        <BSBreadcrumb.Item
          key={n.id}
          href={Routes.getNode.pathFn(document.getDocumentId(), n.id)}
          to={Routes.getNode.pathFn(document.getDocumentId(), n.id)}
          active={n === node}
          as={Link}
        >
          {n.content}
        </BSBreadcrumb.Item>
      ))}
    </BSBreadcrumb>
  );
}

import { Link, useNavigate } from "react-router-dom";
import React from "react";

import { DynalistDocument } from "../../../service/dynalist/document/DynalistDocument";
import { documentParameterRoutes, Routes } from "../../../route";
import { getProjectsNode } from "../../../service/getNode";
import { sortProjects } from "./DocumentMenuPage.service";
import { AButtonThatFuckingDoesImportantThings } from "../../component/AButtonThatFuckingDoesImportantThings";

import "./DocumentMenuPage.scss";

export function SortProjectsButton({ document }: { document: DynalistDocument }) {
  const navigate = useNavigate();

  async function handleSortProjects() {
    await document.refresh();
    sortProjects(document);
    navigate(Routes.getNode.pathFn(document.getDocumentId(), getProjectsNode(document).id));
  }

  return (
    <AButtonThatFuckingDoesImportantThings
      ariaLabel="Refresh"
      importantThings={handleSortProjects}
      waitingLabel="Sort projects..."
    >
      Sort projects
    </AButtonThatFuckingDoesImportantThings>
  );
}

export function DocumentMenuPage({ document }: { document: DynalistDocument }) {
  return (
    <div className="document-menu-page">
      <h2>Pages</h2>
      <div className="document-menu-page__item-container mb-5">
        {documentParameterRoutes
          .filter((route) => route !== Routes.getDocumentMenu)
          .map((route, i) => (
            <div key={i} className="document-menu-page__item">
              <Link to={route.pathFn(document.getDocumentId())}>{route.title}</Link>
            </div>
          ))}
      </div>
      <h2>Actions</h2>
      <div className="document-menu-page__item-container mb-5">
        <div className="document-menu-page__item">
          <SortProjectsButton document={document} />
        </div>
      </div>
    </div>
  );
}

export type NoParameterPathFn = () => string;
export interface NoParameterRouteSpec {
  title: string;
  pathFn: NoParameterPathFn;
}

export type DocumentNodePathFn = (documentId: string, nodeId: string) => string;
export interface DocumentNodeRouteSpec {
  title: string;
  pathFn: DocumentNodePathFn;
}

export type DocumentPathFn = (documentId: string) => string;
export interface DocumentRouteSpec {
  title: string;
  pathFn: DocumentPathFn;
}

export type RouteSpec = NoParameterRouteSpec | DocumentNodeRouteSpec | DocumentRouteSpec;

export const Routes = {
  // no parameter pages
  getHome: {
    title: "Home",
    pathFn() {
      return "/";
    },
  },

  // document & node id pages
  getCalendarView: {
    title: "Calendar",
    pathFn(documentId: string, nodeId: string) {
      return `/${documentId}/${nodeId}/calendar`;
    },
  },
  getCategorize: {
    title: "Categorize",
    pathFn(documentId: string, nodeId: string) {
      return `/${documentId}/${nodeId}/categorize`;
    },
  },
  getKanban: {
    title: "Kanban",
    pathFn(documentId: string, nodeId: string) {
      return `/${documentId}/${nodeId}/kanban`;
    },
  },
  getNode: {
    title: "Node",
    pathFn(documentId: string, nodeId: string) {
      return `/${documentId}/${nodeId}/node`;
    },
  },
  getPlan: {
    title: "Plan",
    pathFn(documentId: string, nodeId: string) {
      return `/${documentId}/${nodeId}/plan`;
    },
  },
  getSizesView: {
    title: "Sizes",
    pathFn(documentId: string, nodeId: string) {
      return `/${documentId}/${nodeId}/size`;
    },
  },
  getTags: {
    title: "Tags",
    pathFn(documentId: string, nodeId: string) {
      return `/${documentId}/${nodeId}/tags`;
    },
  },

  // document id pages
  getBujoMonths: {
    title: "Bujo Months",
    pathFn(documentId: string) {
      return `/${documentId}/bujomonths`;
    },
  },
  getLatestProjects: {
    title: "Latest Projects",
    pathFn(documentId: string) {
      return `/${documentId}/latestprojects`;
    },
  },
  getBumpProject: {
    title: "Bump Project",
    pathFn(documentId: string) {
      return `/${documentId}/bumpproject`;
    },
  },
  getDocumentMenu: {
    title: "Document Menu",
    pathFn(documentId: string) {
      return `/${documentId}/documentmenu`;
    },
  },
  getAdd: {
    title: "Insert",
    pathFn(documentId: string) {
      return `/${documentId}/add`;
    },
  },
  getTrackerPointOfSale: {
    title: "Tracker Point of Sale",
    pathFn(documentId: string) {
      return `/${documentId}/trackerpos`;
    },
  },
};

export const noParameterRoutes: NoParameterRouteSpec[] = [
  //
  Routes.getHome,
];

export const documentNodeParameterRoutes: DocumentNodeRouteSpec[] = [
  Routes.getCalendarView,
  Routes.getCategorize,
  Routes.getKanban,
  Routes.getNode,
  Routes.getPlan,
  Routes.getSizesView,
  Routes.getTags,
];

export const documentParameterRoutes: DocumentRouteSpec[] = [
  Routes.getBujoMonths,
  Routes.getLatestProjects,
  Routes.getBumpProject,
  Routes.getDocumentMenu,
  Routes.getTrackerPointOfSale,
  Routes.getAdd,
];

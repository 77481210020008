import { Col, Row } from "react-bootstrap";
import React from "react";

import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { flattenDepthFirst, getTagsFromTree } from "../../../service/dynalist/nodes";
import { NodeTree } from "../../component/NodeTree";
import { NodeContent } from "../../component/NodeContent";

function isRegularTag(tag: string) {
  return (
    !tag.startsWith("@tg") && !tag.startsWith("@bujomonth:") && !/^#+$/.test(tag) && tag.substring(1).match(/[^0-9]/)
  );
}

function TagsListing({ node, document }: { node: TreeNode; document: DynalistDocument }) {
  return (
    <>
      {getTagsFromTree(node)
        .filter(isRegularTag)
        .map((tag) => (
          <div key={tag}>
            <p>{tag}</p>
            <NodeTree
              document={document}
              nodes={flattenDepthFirst(node)
                .filter((node) => node.content.includes(tag))
                .filter((node) => !node.checked)}
            />
          </div>
        ))}
    </>
  );
}

export function TagsPage({ document, node }: { document: DynalistDocument; node: TreeNode }) {
  return (
    <>
      <Row>
        <Col className="mb-3">
          <NodeContent node={node} document={document} />
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <TagsListing document={document} node={node} />
        </Col>
      </Row>
    </>
  );
}

import EventEmitter from "eventemitter3";

import { Delete, Edit, Insert, Move, RawNode } from "../client/DynalistClient";
import { TreeNode } from "./DynalistDocument";
import { buildNodeTree, findNode, flattenDepthFirst } from "../nodes";
import { addAtIndex } from "../../arrays";

export class NonExistentIdError extends Error {}

export class IndexOutOfRangeError extends Error {}

type InsertWithId = Insert & { id: string };

const CHANGE_EVENT = "change";

export class LocalCache {
  private readonly eventEmitter = new EventEmitter();
  private nodeTreeCache: TreeNode | undefined;
  private nodes: RawNode[] = [];

  load(nodes: RawNode[]) {
    this.nodes = nodes;
    this.nodeTreeCache = undefined;
    this.emitChangeEvent();
  }

  validateNodeIdExists(id: string) {
    for (const node of this.nodes) {
      if (node.id === id) {
        return;
      }
    }
    throw new NonExistentIdError(id);
  }

  validateIndexIsSane(parentId: string, index: number) {
    const length = this.getNode(parentId)?.children?.length || 0;
    if (index < -1 || index > length) {
      throw new IndexOutOfRangeError();
    }
  }

  insert({ id, content, parent_id, index }: Omit<InsertWithId, "action">) {
    this.invalidateNodeTreeCache();
    const parentNode = this.nodes.find((n) => n.id === parent_id);
    if (!parentNode) {
      throw new Error(`Couldn't find parent '${parent_id}'`);
    }
    this.nodes.push({
      id,
      content,
      note: "",
      created: new Date().getTime(),
      modified: new Date().getTime(),
    });
    parentNode.children
      ? parentNode.children.splice(index === -1 ? parentNode.children.length : index, 0, id)
      : (parentNode.children = [id]);
    this.emitChangeEvent();
  }

  move({ node_id, parent_id, index }: Omit<Move, "action">) {
    this.invalidateNodeTreeCache();
    const oldParent = this.nodes.find((n) => n.children?.includes(node_id));
    if (!oldParent) {
      throw new Error("Couldn't find old parent");
    }
    const newParent = this.nodes.find((n) => n.id === parent_id);
    if (!newParent) {
      throw new Error("Couldn't find new parent");
    }
    oldParent.children?.splice(oldParent.children?.indexOf(node_id), 1);
    if (!newParent.children) {
      newParent.children = [];
    }
    addAtIndex(newParent.children, node_id, index);
    this.emitChangeEvent();
  }

  edit({ node_id, content, checked }: Omit<Edit, "action">) {
    this.invalidateNodeTreeCache();
    const node = this.nodes.find((n) => n.id === node_id);
    if (!node) {
      throw new Error("Couldn't find node");
    }
    if (content !== undefined) node.content = content;
    if (checked !== undefined) node.checked = checked;
    node.modified = new Date().getTime();
    this.emitChangeEvent();
  }

  delete({ node_id }: Omit<Delete, "action">) {
    const node = this.getNode(node_id);
    if (!node) {
      throw new Error("Couldn't find node");
    }
    const idsToDelete = flattenDepthFirst(node).map(({ id }) => id);
    const parentToUpdate = node.parent?.id;

    this.invalidateNodeTreeCache();
    this.nodes = this.nodes.filter(({ id }) => !idsToDelete.includes(id));
    const parent = this.nodes.find((n) => n.id === parentToUpdate);
    if (!parent) {
      throw new Error("Couldn't find parent");
    }
    parent.children?.splice(parent.children?.indexOf(node_id), 1);
    this.emitChangeEvent();
  }

  getNode(id: string): TreeNode | undefined {
    if (!this.nodeTreeCache) {
      this.nodeTreeCache = buildNodeTree(this.nodes, "root");
    }

    return findNode(id, this.nodeTreeCache);
  }

  changeId(temporaryId: string, permanentId: string) {
    this.invalidateNodeTreeCache();
    for (const node of this.nodes) {
      if (node.id === temporaryId) {
        node.id = permanentId;
      }
      if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          if (node.children[i] === temporaryId) {
            node.children[i] = permanentId;
          }
        }
      }
    }
  }

  addChangeListener(fn: () => void) {
    this.eventEmitter.addListener(CHANGE_EVENT, fn);
  }

  removeChangeListener(fn: () => void) {
    this.eventEmitter.removeListener(CHANGE_EVENT, fn);
  }

  private emitChangeEvent() {
    this.eventEmitter.emit(CHANGE_EVENT);
  }

  private invalidateNodeTreeCache() {
    this.nodeTreeCache = undefined;
  }
}

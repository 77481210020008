let temporaryIdSequence = 1000;

const prefix = "temp";

export function generateTemporaryId() {
  return prefix + temporaryIdSequence++;
}

export function isTemporaryId(id: string) {
  return id.startsWith(prefix);
}

import React from "react";

import { DynalistAccessTokenNotSetError, getDynalistAccessToken } from "./localStorage";
import { RealApp } from "./RealApp";
import { SetAccessToken } from "./SetAccessToken";

import "./App.scss";

function App() {
  try {
    getDynalistAccessToken();

    return <RealApp />;
  } catch (e) {
    if (e instanceof DynalistAccessTokenNotSetError) {
      return <SetAccessToken />;
    } else {
      throw e;
    }
  }
}

export default App;

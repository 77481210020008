import React from "react";
import { BiUndo } from "react-icons/bi";

import { InlineIconButton } from "./InlineIconButton";
import { DynalistDocument } from "../../service/dynalist/document/DynalistDocument";

export function UndoButton({ document }: { document: DynalistDocument }) {
  async function undo() {
    document.undo();
  }

  return (
    <InlineIconButton ariaLabel="Refresh" onClick={undo} disabled={!document.hasUndoableChanges()}>
      <BiUndo />
    </InlineIconButton>
  );
}

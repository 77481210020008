import { getDynalistAccessToken } from "./localStorage";
import { HttpDynalistClient } from "./service/dynalist/client/HttpDynalistClient";
import { DynalistDocumentStorage } from "./service/dynalist/documentstorage/DynalistDocumentStorage";

function create() {
  const dynalistClient = new HttpDynalistClient(getDynalistAccessToken());
  const dynalistDocumentStorage = new DynalistDocumentStorage(dynalistClient);

  return {
    dynalistClient,
    dynalistDocumentStorage,
  };
}

export type Deps = ReturnType<typeof create>;
let deps: Deps;

export function getRuntimeDeps(): Deps {
  if (!deps) {
    deps = create();
  }

  return deps;
}

import { Spinner } from "react-bootstrap";
import React from "react";

import { MainContainer } from "./MainContainer";

export function Loading() {
  return (
    <MainContainer>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </MainContainer>
  );
}

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { countDescendantNodes, countSize, formatBytesToHumanReadable } from "../../../service/size";
import { SingularCheckbox } from "../../component/SingularCheckbox";

function getChildren(node: TreeNode, sortBySize: boolean): TreeNode[] {
  if (sortBySize) {
    return [...node.children].sort((a, b) => countSize(b) - countSize(a));
  } else {
    return node.children;
  }
}

function Node({ node, sortBySize }: { node: TreeNode; sortBySize: boolean }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Row>
        <Col xs="auto">
          <SingularCheckbox label="" onCheckChange={setShow} />
        </Col>
        <Col>{node.content}</Col>
        {!show && (
          <>
            <Col xs="auto">
              <span className=" text-secondary small">{countDescendantNodes(node)} descendants</span>
            </Col>
            <Col xs="auto">
              <span className="text-secondary small">{formatBytesToHumanReadable(countSize(node))}</span>
            </Col>
          </>
        )}
      </Row>
      {show &&
        getChildren(node, sortBySize).map((child) => (
          <div className="ps-5">
            <Node node={child} sortBySize={sortBySize} />
          </div>
        ))}
    </>
  );
}

export function SizesViewPage({ document, node }: { document: DynalistDocument; node: TreeNode }) {
  const [sortBySize, setSortBySize] = useState(false);

  return (
    <>
      <h1>Sizes</h1>
      <Row>
        <Col></Col>
        <Col xs="auto">
          <SingularCheckbox label="Sort by size" onCheckChange={setSortBySize} />
        </Col>
      </Row>

      <div>
        <Node node={node} sortBySize={sortBySize} />
      </div>
    </>
  );
}

import React from "react";
import { BiRefresh } from "react-icons/bi";

import { DynalistDocument } from "../../service/dynalist/document/DynalistDocument";
import { AButtonThatFuckingDoesImportantThings } from "./AButtonThatFuckingDoesImportantThings";

export function RefreshButton({ document }: { document: DynalistDocument }) {
  async function refresh() {
    await document.refresh();
  }

  return (
    <AButtonThatFuckingDoesImportantThings ariaLabel="Refresh" importantThings={refresh} waitingLabel="Refresh...">
      <BiRefresh />
    </AButtonThatFuckingDoesImportantThings>
  );
}

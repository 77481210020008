import moment from "moment";

import { BujoMonth } from "./bujoMonthsService";
import { TreeNode } from "../../../service/dynalist/document/DynalistDocument";

export interface Day {
  year: number;
  month: number;
  day: number;
  node?: TreeNode;
  bujoMonth: BujoMonth;
}

export function dayToMoment(day: Day) {
  return moment()
    .year(day.year)
    .month(day.month - 1)
    .date(day.day);
}

import EventEmitter from "eventemitter3";

import { Change, Delete, DynalistClient, Edit, Insert, Move } from "../client/DynalistClient";
import { generateTemporaryId, isTemporaryId } from "../temporaryIds";

const PERMANENT_ID_EVENT = "permanentId";

type InsertWithTemporaryId = Insert & { temporaryId: string };
type ChangesQueueAction = InsertWithTemporaryId | Move | Edit | Delete;

export class ChangesQueue {
  private readonly eventEmitter = new EventEmitter();
  private readonly queue: ChangesQueueAction[] = [];
  private ongoingFlush = false;
  private waitChangesFlushedPromise?: Promise<void>;
  private resolveWaitChangesFlushedPromise?: () => void;

  constructor(private documentId: string, private dynalistClient: DynalistClient) {}

  insert(action: Omit<Insert, "action">): string {
    const temporaryId = generateTemporaryId();
    const { parent_id, index, content } = action;
    this.enqueue({ action: "insert", parent_id, index, content, temporaryId });

    return temporaryId;
  }

  move(action: Omit<Move, "action">) {
    const { node_id, parent_id, index } = action;
    this.enqueue({ action: "move", node_id, parent_id, index });
  }

  edit(action: Omit<Edit, "action">) {
    const { node_id, content, checked } = action;
    this.enqueue({
      action: "edit",
      node_id,
      content,
      checked,
    });
  }

  delete(action: Omit<Delete, "action">) {
    const { node_id } = action;
    this.enqueue({
      action: "delete",
      node_id,
    });
  }

  private enqueue(action: ChangesQueueAction) {
    this.queue.push(action);
    setTimeout(() => {
      this.attemptFlushQueue();
    }, 0);
  }

  addPermanentIdListener(fn: (permanentIds: { temporaryId: string; permanentId: string }[]) => void) {
    this.eventEmitter.addListener(PERMANENT_ID_EVENT, fn);
  }

  removePermanentIdListener(fn: (permanentIds: { temporaryId: string; permanentId: string }[]) => void) {
    this.eventEmitter.removeListener(PERMANENT_ID_EVENT, fn);
  }

  private attemptFlushQueue() {
    if (this.ongoingFlush) {
      // no-op, queue will be exhausted by ongoing process
    } else {
      (async () => {
        this.ongoingFlush = true;
        while (this.queue.length > 0) {
          await this.flushQueue();
        }
        this.resolveWaitChangesFlushed();
        this.ongoingFlush = false;
      })();
    }
  }

  private async flushQueue() {
    if (this.queue.length === 0) {
      return;
    }
    const actionsToFlush = this.getSuitableChunkOfChanges();

    const temporaryIds: string[] = [];
    const changes: Change[] = [];
    for (const actionToFlush of actionsToFlush) {
      switch (actionToFlush.action) {
        case "insert":
          {
            const { action, parent_id, index, content, temporaryId } = actionToFlush;
            temporaryIds.push(temporaryId);
            changes.push({
              action,
              parent_id,
              index,
              content,
            });
          }
          break;
        case "move":
          {
            const { action, node_id, parent_id, index } = actionToFlush;
            changes.push({
              action,
              node_id,
              parent_id,
              index,
            });
          }
          break;
        case "edit":
          {
            const { action, node_id, content, checked } = actionToFlush;
            changes.push({
              action,
              node_id,
              content,
              checked,
            });
          }
          break;
        case "delete":
          {
            const { action, node_id } = actionToFlush;
            changes.push({
              action,
              node_id,
            });
          }
          break;
        default:
          throw new Error(`Unknown action type '${JSON.stringify(actionToFlush)}'`);
      }
    }
    const { new_node_ids } = await this.dynalistClient.editDocument(this.documentId, changes);
    if (new_node_ids.length !== temporaryIds.length) {
      throw new Error(`Received ${new_node_ids.length} new_node_ids for ${temporaryIds.length} temporaryIds`);
    }
    const permanentIds = new_node_ids.map((permanentId, index) => ({
      temporaryId: temporaryIds[index],
      permanentId,
    }));
    this.updateQueueWithPermanentIds(permanentIds);
    this.eventEmitter.emit(PERMANENT_ID_EVENT, permanentIds);
  }

  private updateQueueWithPermanentIds(permanentIds: { temporaryId: string; permanentId: string }[]) {
    for (const queuedAction of this.queue) {
      switch (queuedAction.action) {
        case "insert":
          updateIdIfNewAvailable(queuedAction, "parent_id", permanentIds);
          break;
        case "move":
          updateIdIfNewAvailable(queuedAction, "node_id", permanentIds);
          updateIdIfNewAvailable(queuedAction, "parent_id", permanentIds);
          break;
        case "edit":
          updateIdIfNewAvailable(queuedAction, "node_id", permanentIds);
          break;
        case "delete":
          updateIdIfNewAvailable(queuedAction, "node_id", permanentIds);
          break;
        default:
          throw new Error(`Unknown action type '${JSON.stringify(queuedAction)}'`);
      }
    }
  }

  private getSuitableChunkOfChanges() {
    const suitableChunk: ChangesQueueAction[] = [];
    while (this.queue.length > 0 && !usesTemporaryId(this.queue[0])) {
      suitableChunk.push(this.queue.shift()!);
    }

    return suitableChunk;
  }

  private resolveWaitChangesFlushed() {
    // sanity check, fail if only one of two related fields set
    if (
      (this.resolveWaitChangesFlushedPromise && !this.waitChangesFlushedPromise) ||
      (!this.resolveWaitChangesFlushedPromise && this.waitChangesFlushedPromise)
    ) {
      throw new Error("Something is wrong");
    }
    if (this.resolveWaitChangesFlushedPromise) {
      this.resolveWaitChangesFlushedPromise();
      this.waitChangesFlushedPromise = undefined;
      this.resolveWaitChangesFlushedPromise = undefined;
    }
  }

  async waitChangesFlushed() {
    if (this.queue.length === 0) {
      return;
    } else {
      if (!this.waitChangesFlushedPromise) {
        this.waitChangesFlushedPromise = new Promise((resolve) => {
          this.resolveWaitChangesFlushedPromise = resolve;
        });
      }

      return this.waitChangesFlushedPromise;
    }
  }
}

function usesTemporaryId(action: ChangesQueueAction) {
  switch (action.action) {
    case "insert":
      return isTemporaryId(action.parent_id);
    case "move":
      return isTemporaryId(action.node_id) || isTemporaryId(action.parent_id);
    case "edit":
      return isTemporaryId(action.node_id);
    case "delete":
      return isTemporaryId(action.node_id);
    default:
      throw new Error(`Unknown action type: '${JSON.stringify(action)}'`);
  }
}

function updateIdIfNewAvailable(
  action: any,
  field: string,
  permanentIds: { temporaryId: string; permanentId: string }[]
) {
  const id = action[field];
  const newId = permanentIds.find((permanentId) => permanentId.temporaryId === id)?.permanentId;
  if (newId) {
    action[field] = newId;
  }
}

import React, { useMemo, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import moment from "moment";

import { DynalistDocument } from "../../../service/dynalist/document/DynalistDocument";
import { getPointOfSaleHistory, getPointOfSaleOptions } from "../../../service/trackers";

import "./TrackerPointOfSalePage.scss";

function PulsatingButton({
  label,
  className,
  onClick,
}: {
  label: string;
  className?: string;
  onClick: () => void;
}): JSX.Element {
  const [isActive, setIsActive] = useState<boolean>(false);

  function handleClick(): void {
    onClick();
    setIsActive(true);
    // Optionally, reset the effect after some time
    setTimeout(() => {
      setIsActive(false);
    }, 3 * 300); // Adjust time as needed
  }

  return (
    <Button
      variant="warning"
      className={(className || "") + (isActive ? " pulsating-effect" : "")}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
}

function createdTrackedEvent(document: DynalistDocument, parentNodeId: string, item: string) {
  document.insert(item, parentNodeId, 0);
}

export function TrackerPointOfSalePage({ document }: { document: DynalistDocument }) {
  const posOptions = useMemo(() => getPointOfSaleOptions(document), [document]);

  const history = getPointOfSaleHistory(document);

  return (
    <Row>
      <Col xs={12} sm={12} md={6}>
        {posOptions.map((posOption, i) => (
          <Card key={i} className="me-1 mb-2" style={{ display: "inline-block" }}>
            <Card.Body>
              <Card.Title>{posOption.title}</Card.Title>
              {posOption.items.map((item, i) => (
                <React.Fragment key={i}>
                  <PulsatingButton
                    className="me-2 mb-2"
                    label={item}
                    onClick={() => {
                      createdTrackedEvent(document, posOption.nodeId, item);
                    }}
                  />
                </React.Fragment>
              ))}
            </Card.Body>
          </Card>
        ))}
      </Col>
      <Col xs={12} sm={12} md={6}>
        <code>
          <pre>
            {history.map((item, i) => (
              <React.Fragment key={i}>
                {i > 0 &&
                  moment(history[i - 1].date).format("YYYY-MM-DD") !== moment(item.date).format("YYYY-MM-DD") && (
                    <>
                      <br />
                    </>
                  )}
                {moment(item.date).format("YYYY-MM-DD HH:mm")} {item.content}
                <br />
              </React.Fragment>
            ))}
          </pre>
        </code>
      </Col>
    </Row>
  );
}

import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import React, { useState } from "react";

import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { Routes } from "../../../route";
import { SingularCheckbox } from "../../component/SingularCheckbox";
import { Breadcrumb } from "../../component/Breadcrumb";
import { NodeTree } from "../../component/NodeTree";

const initialStateHideChildren = true;

export function NodePage({ document, node }: { document: DynalistDocument; node: TreeNode }) {
  const navigate = useNavigate();
  const [hideChildren, setHideChildren] = useState(initialStateHideChildren);

  function getNodes(node: TreeNode): TreeNode[] {
    return [
      {
        ...node,
        children: node.children.map((child) => ({
          ...child,
          children: hideChildren ? [] : child.children,
        })),
      },
    ];
  }

  return (
    <>
      <Row>
        <Col></Col>
        <Col xs="auto">
          <SingularCheckbox
            label="Hide children"
            onCheckChange={setHideChildren}
            initialState={initialStateHideChildren}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Breadcrumb document={document} node={node} />
          <NodeTree
            document={document}
            nodes={getNodes(node)}
            action={{
              onClick(node: TreeNode) {
                navigate(Routes.getNode.pathFn(document.getDocumentId(), node.id));
              },
              tooltip: "Navigate",
              cursor: "pointer",
            }}
          />
        </Col>
      </Row>
    </>
  );
}

import React, { useState } from "react";

import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { ResponsiveLeftSqueezeRightScrollableLayout } from "../../component/ResponsiveLeftSqueezeRightScrollableLayout";
import { getReasonableControlTagNodes } from "../../../service/controlTagTarget";
import { LocalStorageBackedMultiToggleButton } from "../../component/LocalStorageBackedMultiToggleButton";
import { NodeTree } from "../../component/NodeTree";
import { Mode, TgaaTargetsOrLatestProjects } from "../../component/TgaaTargetsOrLatestProjects";

import "./BumpProject.scss";

function bump(document: DynalistDocument, nodeId: string) {
  // update modified timestamp by setting content to itself
  // note: important to fetch real contents, and not use node.content possibly altered for presentation
  document.edit(nodeId, { content: document.getNode(nodeId)!.content });
}

function createAction(document: DynalistDocument) {
  return {
    onClick(node: TreeNode) {
      bump(document, node.id);
    },
    tooltip: "Bump",
    cursor: "crosshair",
  };
}

function Projects({ document }: { document: DynalistDocument }) {
  return (
    <ResponsiveLeftSqueezeRightScrollableLayout
      left={
        <>
          <p>Latest Projects</p>
          <NodeTree
            document={document}
            nodes={getReasonableControlTagNodes(document, "tg").map((n) => ({ ...n.node, children: [] }))}
          />
        </>
      }
      right={
        <>
          <p>Projects</p>
          <TgaaTargetsOrLatestProjects
            document={document}
            action={createAction(document)}
            modes={[Mode.listGroupProjects]}
          />
        </>
      }
    />
  );
}

function LatestProjects({ document }: { document: DynalistDocument }) {
  return (
    <>
      <p>Latest Projects</p>
      <TgaaTargetsOrLatestProjects
        document={document}
        action={createAction(document)}
        modes={[Mode.listGroupLatestProjects]}
      />
    </>
  );
}

enum BumpProjectMode {
  projects = "projects",
  latestProjects = "latest-projects",
}

const modes = Object.values(BumpProjectMode);

function renderMode(mode: BumpProjectMode, document: DynalistDocument) {
  switch (mode) {
    case BumpProjectMode.projects:
      return <Projects document={document} />;
    case BumpProjectMode.latestProjects:
      return <LatestProjects document={document} />;
    default:
      return <div>Unknown mode</div>;
  }
}

export function BumpProject({ document }: { document: DynalistDocument }) {
  const [mode, setMode] = useState<BumpProjectMode>(modes[0] as BumpProjectMode);

  return (
    <>
      <div className="bump_project__view_mode">
        <LocalStorageBackedMultiToggleButton
          modes={modes}
          storageKey="bump_project_view_mode"
          buttonLabel="Change view"
          onChange={(value) => setMode(value as BumpProjectMode)}
        />
      </div>
      {renderMode(mode, document)}
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

export function LocalStorageBackedMultiToggleButton({
  modes,
  storageKey,
  buttonLabel,
  onChange,
}: {
  modes: string[];
  storageKey: string;
  buttonLabel: string;
  onChange: (value: string) => void;
}) {
  const [value, setValue] = useState(modes[0]);

  // Load the initial state from local storage
  useEffect(() => {
    const storedValue = localStorage.getItem(storageKey);
    if (storedValue) {
      setValue(storedValue);
    }
  }, [storageKey]);

  // Update local storage when inputValue changes
  useEffect(() => {
    localStorage.setItem(storageKey, value);
    onChange(value);
  }, [storageKey, onChange, value]);

  function onClick(event: React.MouseEvent<HTMLButtonElement>) {
    const currentIndex = modes.indexOf(value);
    const nextIndex = (currentIndex + 1) % modes.length;
    setValue(modes[nextIndex]);
  }

  return (
    <Button variant="light" onClick={onClick}>
      {buttonLabel}
    </Button>
  );
}

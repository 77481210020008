import moment from "moment";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Button } from "react-bootstrap";

import { Month, nextMonth, previousMonth } from "./Month";
import { BujoMonth, getBujoMonths } from "./bujoMonthsService";
import { DynalistDocument } from "../../../service/dynalist/document/DynalistDocument";
import { ViewBujoMonth } from "./ViewBujoMonth";
import { useFixedNodeMapKeys } from "../../component/useFixedNodeMapKeys";

function getCurrentMonth(): Month {
  return { month: moment().month() + 1, year: moment().year() };
}

type View =
  | {
      view: "list bujo months";
    }
  | {
      view: "bujo month";
      bujoMonth: BujoMonth;
      month: Month;
    };

function getInitialView(): View {
  return { view: "list bujo months" };
}

function ListBujoMonths({
  document,
  month,
  setView,
}: {
  document: DynalistDocument;
  month: Month;
  setView: Dispatch<SetStateAction<View>>;
}) {
  const fixedNodeMapKeys = useFixedNodeMapKeys(document);

  const bujoMonths = getBujoMonths(document);

  return (
    <>
      <h2>
        {month.month}/{month.year}
      </h2>

      {bujoMonths.map(({ nodeId, name }) => (
        <div key={fixedNodeMapKeys.getFixedNodeKey(nodeId)}>
          <Button onClick={() => setView({ view: "bujo month", bujoMonth: { nodeId, name }, month })} variant="link">
            {name}
          </Button>
        </div>
      ))}
    </>
  );
}

export function BujoMonthsPage({ document }: { document: DynalistDocument }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [month, setMonth] = useState(getCurrentMonth());
  const [view, setView] = useState(getInitialView());

  function up() {
    setView({ view: "list bujo months" });
  }

  function left() {
    setMonth(previousMonth);
  }

  function right() {
    setMonth(nextMonth);
  }

  switch (view.view) {
    case "list bujo months":
      return <ListBujoMonths document={document} month={month} setView={setView} />;
    case "bujo month":
      return (
        <ViewBujoMonth document={document} bujoMonth={view.bujoMonth} month={month} up={up} left={left} right={right} />
      );
    default:
      return <p>dunno</p>;
  }
}

import React, { ReactNode } from "react";

import "./ToolboxRevealOnHover.scss";

export function ToolboxRevealOnHover({ toolbox, children }: { toolbox: ReactNode; children: ReactNode }) {
  return (
    <div className="hover-toolbox">
      <div>{children}</div>
      <div className="hover-toolbox__toolbox">{toolbox}</div>
    </div>
  );
}

import { DynalistClient, RawNode } from "../client/DynalistClient";
import { LocalCache } from "./LocalCache";
import { ChangesQueue } from "./ChangesQueue";
import { isAncestorDescendantRelationship } from "../nodes";

export class ModificationsDisallowedError extends Error {}

export interface TreeNode {
  id: string;
  content: string;
  parent?: TreeNode;
  checked?: boolean;
  children: TreeNode[];
  created: number;
  modified: number;
}

class NonUndoCapableDynalistDocument {
  private localCache: LocalCache;
  private changesQueue: ChangesQueue;
  private isModificationAllowed = true;

  constructor(private documentId: string, nodes: RawNode[], private dynalistClient: DynalistClient) {
    this.localCache = new LocalCache();
    this.localCache.load(nodes);
    this.changesQueue = new ChangesQueue(documentId, dynalistClient);
    this.changesQueue.addPermanentIdListener((permanentIds: { temporaryId: string; permanentId: string }[]) => {
      for (const { temporaryId, permanentId } of permanentIds) {
        this.localCache.changeId(temporaryId, permanentId);
      }
    });
  }

  async refresh() {
    this.disallowModifications();
    await this.changesQueue.waitChangesFlushed();

    const document = await this.dynalistClient.readDocument(this.documentId);
    this.localCache.load(document.nodes);

    this.allowModifications();
  }

  getDocumentId() {
    return this.documentId;
  }

  insert(content: string, parentId: string, index: number): string {
    if (!this.isModificationAllowed) {
      throw new ModificationsDisallowedError();
    }
    this.localCache.validateNodeIdExists(parentId);
    this.localCache.validateIndexIsSane(parentId, index);
    const action = {
      content,
      parent_id: parentId,
      index,
    };
    const temporaryId = this.changesQueue.insert(action);
    this.localCache.insert({
      id: temporaryId,
      ...action,
    });

    return temporaryId;
  }

  move(nodeId: string, newParentId: string, index: number) {
    if (!this.isModificationAllowed) {
      throw new ModificationsDisallowedError();
    }
    this.localCache.validateNodeIdExists(nodeId);
    this.localCache.validateNodeIdExists(newParentId);
    if (isAncestorDescendantRelationship({ ancestor: this.getNode(nodeId)!, descendant: this.getNode(newParentId)! })) {
      throw new Error("Can't move parent inside child");
    }
    this.localCache.validateIndexIsSane(newParentId, index);
    // dynalist has bug where multiple moves to same parent using index -1 gets reversed order, so replace index -1 with
    // proper index based on local cache https://talk.dynalist.io/t/multiple-move-actions-with-index-1-get-stored-in-reverse-order/8243
    if (index === -1) {
      index = this.localCache.getNode(newParentId)?.children.length || 0;
    }
    const action = {
      node_id: nodeId,
      parent_id: newParentId,
      index,
    };

    this.changesQueue.move(action);
    this.localCache.move(action);
  }

  edit(nodeId: string, changes: { content?: string; checked?: boolean }) {
    if (!this.isModificationAllowed) {
      throw new ModificationsDisallowedError();
    }
    this.localCache.validateNodeIdExists(nodeId);
    const { content, checked } = changes;
    const action = {
      node_id: nodeId,
      content,
      checked,
    };

    this.localCache.edit(action);
    this.changesQueue.edit(action);
  }

  delete(nodeId: string) {
    // TODO: figure way to test this (not public api, has to be tested indirectly) and add test
    if (!this.isModificationAllowed) {
      throw new ModificationsDisallowedError();
    }
    // TODO: figure way to test this (not public api, has to be tested indirectly) and add test
    this.localCache.validateNodeIdExists(nodeId);
    // TODO: figure way to test this (not public api, has to be tested indirectly) and add test
    if (nodeId === "root") {
      throw new Error("Can't delete root");
    }

    const action = { node_id: nodeId };
    this.localCache.delete(action);
    this.changesQueue.delete(action);
  }

  getNode(id: string): TreeNode | undefined {
    return this.localCache.getNode(id);
  }

  getRootNode(): TreeNode {
    return this.getNode("root")!;
  }

  addPermanentIdListener(fn: (permanentIds: { temporaryId: string; permanentId: string }[]) => void) {
    this.changesQueue.addPermanentIdListener(fn);
  }

  removePermanentIdListener(fn: (permanentIds: { temporaryId: string; permanentId: string }[]) => void) {
    this.changesQueue.removePermanentIdListener(fn);
  }

  addChangeListener(fn: () => void) {
    this.localCache.addChangeListener(fn);
  }

  removeChangeListener(fn: () => void) {
    this.localCache.removeChangeListener(fn);
  }

  disallowModifications() {
    this.isModificationAllowed = false;
  }

  private allowModifications() {
    this.isModificationAllowed = true;
  }
}

class UndoCapableDynalistDocument {
  private undoList: (() => void)[] = [];
  private nonUndoCapableDynalistDocument: NonUndoCapableDynalistDocument;

  constructor(documentId: string, nodes: RawNode[], private dynalistClient: DynalistClient) {
    this.nonUndoCapableDynalistDocument = new NonUndoCapableDynalistDocument(documentId, nodes, dynalistClient);
  }

  async refresh() {
    return this.nonUndoCapableDynalistDocument.refresh();
  }

  getDocumentId() {
    return this.nonUndoCapableDynalistDocument.getDocumentId();
  }

  insert(content: string, parentId: string, index: number): string {
    const temporaryId = this.nonUndoCapableDynalistDocument.insert(content, parentId, index);

    let permanentId: string;
    const permanentIdListener = createTargetedPermanentIdListener(
      temporaryId,
      (permanentIdValue) => (permanentId = permanentIdValue)
    );
    this.nonUndoCapableDynalistDocument.addPermanentIdListener(permanentIdListener);
    this.undoList.push(() => {
      this.nonUndoCapableDynalistDocument.removePermanentIdListener(permanentIdListener);
      this.nonUndoCapableDynalistDocument.delete(permanentId || temporaryId);
    });

    return temporaryId;
  }

  move(nodeId: string, newParentId: string, index: number) {
    const parentIdBeforeMove = this.getNode(nodeId)?.parent?.id;
    const indexBeforeMove =
      parentIdBeforeMove && this.getNode(parentIdBeforeMove)?.children.findIndex((child) => child.id === nodeId);

    this.nonUndoCapableDynalistDocument.move(nodeId, newParentId, index);

    if (parentIdBeforeMove === undefined) {
      throw new Error("Could not determine parent id before move");
    }
    if (indexBeforeMove === undefined || indexBeforeMove === "") {
      throw new Error("Could not determine index before move");
    }
    this.undoList.push(() => {
      this.nonUndoCapableDynalistDocument.move(nodeId, parentIdBeforeMove, indexBeforeMove);
    });
  }

  edit(nodeId: string, changes: { content?: string; checked?: boolean }) {
    const previousContent = this.getNode(nodeId)?.content;
    const previousChecked = this.getNode(nodeId)?.checked;

    this.nonUndoCapableDynalistDocument.edit(nodeId, changes);

    if (previousContent === undefined) {
      throw new Error("Could not determine previous content");
    }
    this.undoList.push(() => {
      this.nonUndoCapableDynalistDocument.edit(nodeId, { content: previousContent, checked: previousChecked });
    });
  }

  getNode(id: string): TreeNode | undefined {
    return this.nonUndoCapableDynalistDocument.getNode(id);
  }

  getRootNode(): TreeNode {
    return this.nonUndoCapableDynalistDocument.getRootNode();
  }

  addPermanentIdListener(fn: (permanentIds: { temporaryId: string; permanentId: string }[]) => void) {
    this.nonUndoCapableDynalistDocument.addPermanentIdListener(fn);
  }

  removePermanentIdListener(fn: (permanentIds: { temporaryId: string; permanentId: string }[]) => void) {
    this.nonUndoCapableDynalistDocument.removePermanentIdListener(fn);
  }

  addChangeListener(fn: () => void) {
    this.nonUndoCapableDynalistDocument.addChangeListener(fn);
  }

  removeChangeListener(fn: () => void) {
    this.nonUndoCapableDynalistDocument.removeChangeListener(fn);
  }

  disallowModifications() {
    this.nonUndoCapableDynalistDocument.disallowModifications();
  }

  hasUndoableChanges() {
    return this.undoList.length > 0;
  }

  undo() {
    const reverseOperation = this.undoList.pop();
    if (reverseOperation) {
      reverseOperation();
    } else {
      throw new Error("Undo list is empty");
    }
  }
}

// note! this is a simple rename, couldn't figure out another way than extends
export class DynalistDocument extends UndoCapableDynalistDocument {}

export function createTargetedPermanentIdListener(targetTemporaryId: string, callback: (permanentId: string) => {}) {
  function targetedPermanentIdListener(permanentIds: { temporaryId: string; permanentId: string }[]) {
    for (const { temporaryId, permanentId } of permanentIds) {
      if (temporaryId === targetTemporaryId) {
        callback(permanentId);
      }
    }
  }

  return targetedPermanentIdListener;
}

import { DynalistDocument, TreeNode } from "./dynalist/document/DynalistDocument";
import { getControlTagTree, removeControlTags } from "./controlTagTarget";
import { flattenDepthFirst, isLeafNode } from "./dynalist/nodes";
import { parseDateTimeInfoFromStart, parseTimeInfoFromStart, removeTimestampFromStart, withTime } from "./dateandtime";

export function getPointOfSaleHistory(document: DynalistDocument) {
  if (1 > 2)
    return [
      {
        date: new Date(),
        content: "content",
      },
    ];

  return getControlTagTree(document, "pos")
    .flatMap(flattenDepthFirst)
    .filter(isLeafNode)
    .map((n) => document.getNode(n.id)!)
    .flatMap((n) => n.children)
    .map(parseEntry)
    .sort((a, b) => b.date - a.date);
}

export function getPointOfSaleOptions(document: DynalistDocument) {
  const trackedPosCategories = getControlTagTree(document, "pos").flatMap(flattenDepthFirst).filter(isLeafNode);

  return trackedPosCategories.map((posCategory) => {
    const items = Array.from(
      new Set(
        document
          .getNode(posCategory.id)!
          .children.map(parseEntry)
          .slice(0, 10)
          .map((c) => c.content)
      )
    );

    return {
      nodeId: posCategory.id,
      title: removeControlTags(posCategory.content),
      items,
    };
  });
}

export interface Entry {
  date: number;
  nodeId: string;
  content: string;
}

function augmentWithParsedTime(node: TreeNode) {
  const date = new Date(node.created);
  const time = parseTimeInfoFromStart(node.content);

  return withTime(date, time).getTime();
}

export function parseEntry(node: TreeNode) {
  function getDate(node: TreeNode) {
    try {
      return parseDateTimeInfoFromStart(node.content).getTime();
    } catch (e) {
      try {
        return augmentWithParsedTime(node);
      } catch (e) {
        return node.created;
      }
    }
  }

  const date = getDate(node);

  return {
    date,
    nodeId: node.id,
    content: removeTimestampFromStart(node.content),
  };
}

export function parseAndSort(nodes: TreeNode[]): Entry[] {
  if (!nodes) {
    return [];
  }

  const result = nodes.map(parseEntry);

  result.sort((a, b) => b.date - a.date);

  return result;
}

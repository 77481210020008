import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DynalistDocument } from "../../service/dynalist/document/DynalistDocument";
import { useForceUpdate } from "./useForceUpdate";
import { getRuntimeDeps } from "../../runtimeDeps";
import { DocumentNodeRouteSpec } from "../../route";

function useRerenderComponentOnDocumentChange(document?: DynalistDocument) {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!document) {
      return;
    }

    function changeListener() {
      forceUpdate();
    }

    document.addChangeListener(changeListener);

    return () => {
      document.removeChangeListener(changeListener);
    };
  }, [document, forceUpdate]);
}

function useRerenderComponentOnPermanentIdChange(document?: DynalistDocument) {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!document) {
      return;
    }

    function permanentIdListener() {
      forceUpdate();
    }

    document.addPermanentIdListener(permanentIdListener);

    return () => {
      document.removePermanentIdListener(permanentIdListener);
    };
  }, [document, forceUpdate]);
}

export function useDocumentChangeListener(document: DynalistDocument, fn: () => void) {
  useEffect(() => {
    if (!document) {
      return;
    }

    document.addChangeListener(fn);

    return () => {
      document.removeChangeListener(fn);
    };
  }, [document, fn]);
}

export function useDynalistDocument(documentId?: string) {
  const [document, setDocument] = useState<DynalistDocument>();

  useRerenderComponentOnDocumentChange(document);
  useRerenderComponentOnPermanentIdChange(document);

  useEffect(() => {
    setDocument(undefined);
    if (documentId) {
      getRuntimeDeps().dynalistDocumentStorage.getDocument(documentId).then(setDocument);
    }
  }, [documentId]);

  return document;
}

export function useUpdateUrlOnPermanentIdChange(
  document: DynalistDocument | undefined,
  nodeId: string,
  routeSpec: DocumentNodeRouteSpec
) {
  const navigate = useNavigate();

  return useEffect(() => {
    function processPermanentId(permanentIds: { temporaryId: string; permanentId: string }[]) {
      for (const { temporaryId, permanentId } of permanentIds) {
        if (document && temporaryId === nodeId) {
          navigate(routeSpec.pathFn(document.getDocumentId(), permanentId));
        }
      }
    }

    if (document) {
      document.addPermanentIdListener(processPermanentId);
    }

    return () => {
      document?.removePermanentIdListener(processPermanentId);
    };
  }, [document, nodeId, navigate, routeSpec.pathFn]);
}

import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

export function SingularCheckbox({
  label,
  onCheckChange,
  initialState = false,
}: {
  label: string;
  onCheckChange: (checked: boolean) => void;
  initialState?: boolean;
}) {
  const [checked, setChecked] = useState<boolean>(initialState);
  const [id, setId] = useState<string>("");

  useEffect(() => {
    setId(`checkbox-${Math.random().toString(36).substr(2, 9)}`);
  }, []);

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    onCheckChange(isChecked);
  }

  return (
    <Form>
      <Form.Check id={id} type="checkbox" label={label} checked={checked} onChange={handleOnChange} />
    </Form>
  );
}

import React, { KeyboardEvent, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { BiListPlus, BiPencil, BiPin, BiRightArrowAlt } from "react-icons/bi";

import { NicerContentFittingTextArea } from "./NicerContentFittingTextArea";
import { DynalistDocument } from "../../service/dynalist/document/DynalistDocument";
import { InlineIconButton } from "./InlineIconButton";
import { TrashButton } from "./TrashButton";
import { buildUrl } from "../../service/dynalist/client/DynalistUrl";
import { pin } from "../../service/pin";

import "./ToolBox.scss";

function TextAreaDialog({
  title,
  initialValue,
  successButtonLabel,
  onSuccess,
  show,
  handleClose,
}: {
  title: string;
  initialValue: string;
  successButtonLabel: string;
  onSuccess(value: string): void;
  show: boolean;
  handleClose: () => void;
}) {
  const [content, setContent] = useState(initialValue);

  // if initialValue changes, for example document is refreshed, but this component is still on the page we still show
  // the old initialValue, we need to update the content
  useEffect(() => {
    setContent(initialValue);
  }, [initialValue]);

  function handleSubmit() {
    onSuccess(content);
    setContent(initialValue);
    handleClose();
  }

  function handleKeyDown(event: KeyboardEvent<HTMLElement>) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NicerContentFittingTextArea
          value={content}
          autoFocus
          onKeyDown={handleKeyDown}
          className="mb-3"
          onChange={(e) => setContent(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {successButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function EditDialog({
  document,
  nodeId,
  show,
  handleClose,
}: {
  document: DynalistDocument;
  nodeId: string;
  show: boolean;
  handleClose: () => void;
}) {
  return (
    <TextAreaDialog
      title="Edit"
      initialValue={document.getNode(nodeId)!.content} // re-read real node contents, argument may have been altered for display purposes
      successButtonLabel="Save"
      onSuccess={(content) => {
        document.edit(nodeId, { content });
      }}
      show={show}
      handleClose={handleClose}
    />
  );
}

function AddDialog({
  document,
  nodeId,
  show,
  handleClose,
}: {
  document: DynalistDocument;
  nodeId: string;
  show: boolean;
  handleClose: () => void;
}) {
  return (
    <TextAreaDialog
      title="Add child"
      initialValue=""
      successButtonLabel="Add"
      onSuccess={(content) => {
        document.insert(content, nodeId, 0);
      }}
      show={show}
      handleClose={handleClose}
    />
  );
}

export function ToolBox({ document, nodeId }: { document: DynalistDocument; nodeId: string }) {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);

  function doPin() {
    document.edit(nodeId, { content: pin(document.getNode(nodeId)!.content) });
  }

  return (
    <div className="tool-box">
      <InlineIconButton ariaLabel="Edit node" onClick={() => setShowEditDialog(true)}>
        <BiPencil />
      </InlineIconButton>
      <InlineIconButton ariaLabel="Add child node" onClick={() => setShowAddDialog(true)}>
        <BiListPlus />
      </InlineIconButton>
      <TrashButton document={document} nodeId={nodeId} nextFocusAdviceCallback={() => {}} />
      <a
        className="btn btn-link"
        target="_blank"
        href={buildUrl(document.getDocumentId(), nodeId)}
        rel="noreferrer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <BiRightArrowAlt />
      </a>
      <InlineIconButton ariaLabel="Pin" onClick={() => doPin()}>
        <BiPin />
      </InlineIconButton>
      <EditDialog
        document={document}
        nodeId={nodeId}
        show={showEditDialog}
        handleClose={() => setShowEditDialog(false)}
      />
      <AddDialog document={document} nodeId={nodeId} show={showAddDialog} handleClose={() => setShowAddDialog(false)} />
    </div>
  );
}

import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Alert, Button, Container } from "react-bootstrap";

import { MainContainer } from "../../../component/MainContainer";
import { NicerContentFittingTextArea } from "../../../component/NicerContentFittingTextArea";
import { getRuntimeDeps } from "../../../../runtimeDeps";

function AddPage({ documentId }: { documentId: string }) {
  const dynalistClient = getRuntimeDeps().dynalistClient;
  const [params] = useSearchParams();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const [content, setContent] = useState(params.get("content") || "");
  const [childContent, setChildContent] = useState(params.get("childContent") || "");

  async function add() {
    try {
      setLoading(true);

      const response = await dynalistClient.editDocument(documentId, [
        {
          action: "insert",
          parent_id: "root",
          index: 0,
          content,
        },
      ]);

      await dynalistClient.editDocument(documentId, [
        {
          action: "insert",
          parent_id: response.new_node_ids[0],
          index: 0,
          content: childContent,
        },
        {
          action: "insert",
          parent_id: response.new_node_ids[0],
          index: 1,
          content: "",
        },
      ]);

      setDone(true);
    } catch (e) {
      setError((e as any).message);
    } finally {
      setLoading(false);
    }
  }

  let page: React.ReactNode;
  if (error) {
    page = <Alert variant="danger">{error}</Alert>;
  } else if (loading) {
    page = <Alert variant="warning">Loading...</Alert>;
  } else if (done) {
    page = <Alert variant="success">Added!</Alert>;
  } else
    page = (
      <>
        <h1>Add</h1>
        <p>Content</p>
        <NicerContentFittingTextArea value={content} className="mb-3" onChange={(e) => setContent(e.target.value)} />
        <p>Note</p>
        <NicerContentFittingTextArea
          value={childContent}
          className="mb-4"
          onChange={(e) => setChildContent(e.target.value)}
        />
        <div className="text-end">
          <Button onClick={add}>Add</Button>
        </div>
      </>
    );

  return <Container className="mt-4">{page}</Container>;
}

export function AddPageFromParams() {
  const { documentId } = useParams();

  if (!documentId) {
    return (
      <MainContainer>
        <p>Not all parameters present</p>
      </MainContainer>
    );
  }

  return <AddPage documentId={documentId} />;
}

import { TreeNode } from "../service/dynalist/document/DynalistDocument";

export function formatBytesToHumanReadable(number: number): string {
  // format number in bytes to human-readable format
  // https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
  if (number < 1024) {
    return number + " bytes";
  } else if (number < 1048576) {
    return (number / 1024).toFixed(2) + " KB";
  } else if (number < 1073741824) {
    return (number / 1048576).toFixed(2) + " MB";
  } else {
    return (number / 1073741824).toFixed(2) + " GB";
  }
}

export function countDescendantNodes(node: TreeNode): number {
  function count(node: TreeNode): number {
    return 1 + node.children?.reduce((acc, child) => acc + count(child), 0) ?? 0;
  }

  return count(node) - 1;
}

export function countSize(node: TreeNode): number {
  // TODO: Add node.note.length, when your own Dynalist API returns it
  return node.content.length + (node.children?.reduce((acc, child) => acc + countSize(child), 0) ?? 0);
}

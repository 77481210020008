const linkBaseUrl = "https://dynalist.io/d/";

export function buildUrl(file_id: any, node_id: string) {
  let url = linkBaseUrl + file_id;

  if (node_id) {
    url += "#z=" + node_id;
  }

  return url;
}

const dynalistLinkRegexp = new RegExp(linkBaseUrl + "([^/]+)#z=(.+)");

export function containsDynalistLink(text: string) {
  return dynalistLinkRegexp.test(text);
}

export function extractDynalistLink(text: string): { url: string; documentId: string; nodeId: string } {
  const match = text.match(dynalistLinkRegexp);
  if (!match) {
    throw new Error(`Text doesn't contain dynalist link: '${text}'`);
  }

  return {
    url: match[0],
    documentId: match[1],
    nodeId: match[2],
  };
}

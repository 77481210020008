import React, { ReactNode } from "react";
import { Container } from "react-bootstrap";

import "./MainContainer.scss";

function BottomRightGrayLabel({ label }: { label: string }) {
  return (
    <div data-testid="gray-label" className="bottom-right-gray-label">
      {label}
    </div>
  );
}

export function MainContainer({ label, children, fluid }: { label?: string; children: ReactNode; fluid?: boolean }) {
  return (
    <Container className="pt-3 pb-5" fluid={fluid}>
      {children}
      {label && <BottomRightGrayLabel label={label} />}
    </Container>
  );
}

import React, { useState } from "react";

import { DynalistDocument, TreeNode } from "../../service/dynalist/document/DynalistDocument";
import { formatReasonableName, getControlTagTree, getReasonableControlTagNodes } from "../../service/controlTagTarget";
import { NodeTree } from "./NodeTree";
import { LocalStorageBackedMultiToggleButton } from "./LocalStorageBackedMultiToggleButton";
import { ListGroupNodeTree } from "./ListGroupNodeTree";
import { NodeAction } from "./TooltipAndActionNodeContent";

import "./TgaaTargetsOrLatestProjects.scss";

function getLatestProjects(document: DynalistDocument): TreeNode[] {
  return getReasonableControlTagNodes(document, "tg").map((reasonableNode) => ({
    ...reasonableNode.node,
    // TODO: a -> b -> c osapalaset pitäisi renderöidä <NodeContent>:lla, jotta linkit yms boldit rendautuu oikein
    content: formatReasonableName(reasonableNode),
    children: [],
  }));
}

export enum Mode {
  boxesProjects = "boxes-projects",
  boxesLatestProjects = "boxes-latest-projects",
  listGroupProjects = "list-group-projects",
  listGroupLatestProjects = "list-group-latest-projects",
}

const allModes = Object.values(Mode);

function renderMode(mode: Mode, document: DynalistDocument, action?: NodeAction) {
  switch (mode) {
    case Mode.boxesProjects:
      return <NodeTree document={document} nodes={getControlTagTree(document, "tg")} action={action} />;
    case Mode.boxesLatestProjects:
      return <NodeTree document={document} nodes={getLatestProjects(document)} action={action} />;
    case Mode.listGroupProjects:
      return <ListGroupNodeTree document={document} nodes={getControlTagTree(document, "tg")} action={action} />;
    case Mode.listGroupLatestProjects:
      return <ListGroupNodeTree document={document} nodes={getLatestProjects(document)} action={action} />;
    default:
      return <div>Unknown mode</div>;
  }
}

export function TgaaTargetsOrLatestProjects({
  document,
  action,
  modes = allModes,
}: {
  document: DynalistDocument;
  action?: NodeAction;
  modes?: Mode[];
}) {
  const [mode, setMode] = useState<Mode>(modes[0] as Mode);

  return (
    <>
      {modes.length > 1 && (
        <div className="tgaa-targets-or-latest-projects__view_mode">
          <LocalStorageBackedMultiToggleButton
            modes={modes}
            storageKey="tgaa_targets_or_latest_projects_view_mode"
            buttonLabel="Change view"
            onChange={(value) => setMode(value as Mode)}
          />
        </div>
      )}
      <div
        className={`tgaa-targets-or-latest-projects__target-tree tgaa-targets-or-latest-projects__target-tree--${mode}`}
      >
        {renderMode(mode, document, action)}
      </div>
    </>
  );
}

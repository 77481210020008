import { DynalistDocument } from "../../../service/dynalist/document/DynalistDocument";
import { sortByNestedModifiedOrPriority } from "../../../service/sort";
import { getProjectsNode } from "../../../service/getNode";

export function sortProjects(document: DynalistDocument) {
  const projectsNode = getProjectsNode(document);
  const sorted = sortByNestedModifiedOrPriority(projectsNode.children);
  sorted.reverse();
  for (const node of sorted) {
    document.move(node.id, projectsNode.id, 0);
  }
}

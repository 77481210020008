import React, { useEffect, useRef } from "react";

export function ContentFittingTextArea(
  props: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
) {
  const ref = useRef<HTMLTextAreaElement>(null);

  function setHeight() {
    if (ref.current) {
      ref.current.style.height = ref.current.scrollHeight + "px";
    }
  }

  useEffect(() => {
    setHeight();
  });

  return <textarea ref={ref} {...props} rows={1} />;
}

import { Route, Routes } from "react-router-dom";
import React from "react";

import { documentNodeParameterRoutes, documentParameterRoutes, noParameterRoutes } from "./route";
import { getElement } from "./routeToElement";

export function RealApp() {
  return (
    <Routes>
      {noParameterRoutes.map((spec, i) => (
        <Route key={i} path={spec.pathFn()} element={getElement(spec)} />
      ))}
      {documentNodeParameterRoutes.map((spec, i) => (
        <Route key={i} path={spec.pathFn(":documentId", ":nodeId")} element={getElement(spec)} />
      ))}
      {documentParameterRoutes.map((spec, i) => (
        <Route key={i} path={spec.pathFn(":documentId")} element={getElement(spec)} />
      ))}
    </Routes>
  );
}

const firstPriority = "@1st";

function getWords(content: string): string[] {
  return content.split(/\s+/);
}

export function replacePriorityTagsWithIcons(content: string) {
  return getWords(content)
    .map((word) => (word === firstPriority ? "📌" : word))
    .join(" ");
}

export function pin(content: string) {
  const words = getWords(content);

  return words.includes(firstPriority)
    ? words.filter((word) => word !== firstPriority).join(" ")
    : [...words, firstPriority].join(" ");
}

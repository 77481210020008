import { TreeNode } from "./document/DynalistDocument";
import { RawNode } from "./client/DynalistClient";

export function flattenBreadthFirst(node: TreeNode): TreeNode[] {
  const result: TreeNode[] = [];
  const queue: TreeNode[] = [node];
  while (queue.length > 0) {
    const node = queue.shift()!;
    result.push(node);
    queue.push(...node.children);
  }

  return result;
}

export function flattenDepthFirst(node: TreeNode): TreeNode[] {
  const result: TreeNode[] = [];
  const queue: TreeNode[] = [node];
  while (queue.length > 0) {
    const node = queue.shift()!;
    result.push(node);
    queue.unshift(...node.children);
  }

  return result;
}

export function distance({ ancestor, descendant }: { ancestor: TreeNode; descendant: TreeNode }) {
  if (!isAncestorDescendantRelationship({ ancestor, descendant })) {
    throw new Error("Not ancestor descendant relationship");
  }
  let distance = -1;
  const queue = [descendant];
  while (queue.length > 0) {
    distance++;
    const node = queue.shift()!;
    if (node === ancestor) {
      return distance;
    }
    if (node.parent) {
      queue.push(node.parent);
    }
  }
  throw new Error("Should never reach this point if ancestor descendant relationship holds");
}

export function isAncestorDescendantRelationship({
  ancestor,
  descendant,
}: {
  ancestor: TreeNode;
  descendant: TreeNode;
}) {
  const queue = [descendant];
  while (queue.length > 0) {
    const node = queue.shift()!;
    if (node === ancestor) {
      return true;
    }
    if (node.parent) {
      queue.push(node.parent);
    }
  }

  return false;
}

export function findNode(searchId: string, node: TreeNode): TreeNode | undefined {
  if (node.id === searchId) {
    return node;
  }
  for (const child of node.children) {
    const result = findNode(searchId, child);
    if (result) {
      return result;
    }
  }
}

export function toRawNodes(node: TreeNode): RawNode[] {
  const rawNodes: RawNode[] = [];

  const queue: TreeNode[] = [node];
  while (queue.length > 0) {
    const { id, content, checked, children, created, modified } = queue.shift()!;
    rawNodes.push({
      id,
      content,
      note: "",
      checked,
      children: children.map((child) => child.id),
      created,
      modified,
    });
    queue.push(...children);
  }

  return rawNodes;
}

export function cloneDeep(node: TreeNode): TreeNode {
  return buildNodeTree(toRawNodes(node), node.id, node.parent);
}

type MapNode = RawNode & { parent?: string };

function buildNodeMap(nodes: RawNode[]): { [id: string]: MapNode } {
  const nodeMap: { [id: string]: MapNode } = {};

  for (const node of nodes) {
    nodeMap[node.id] = JSON.parse(JSON.stringify(node));
  }

  for (const node of nodes) {
    for (const childNodeId of node.children || []) {
      if (nodeMap[childNodeId]) {
        nodeMap[childNodeId].parent = node.id;
      }
    }
  }

  return nodeMap;
}

export function buildNodeTree(nodes: RawNode[], rootNodeId: string, parent?: TreeNode): TreeNode {
  const nodeMap = buildNodeMap(nodes);

  function recurse(id: string, parent?: TreeNode): TreeNode {
    const { content, checked, created, modified, children = [] } = nodeMap[id];
    const node: TreeNode = {
      id,
      content,
      parent,
      checked,
      created,
      modified,
      children: [],
    };
    node.children = children.map((child) => recurse(child, node));

    return node;
  }

  return recurse(rootNodeId, parent);
}

function getSiblingIndex(parent: TreeNode, node: TreeNode) {
  return parent?.children.map((node) => node.id).indexOf(node.id);
}

export function getLeftSibling(node: TreeNode): string | undefined {
  if (!node.parent) {
    return undefined;
  }
  const parent = node.parent;
  const nthSibling = getSiblingIndex(parent, node);
  if (nthSibling > 0) {
    return parent?.children[nthSibling - 1].id;
  }
}

export function getRightSibling(node: TreeNode): string | undefined {
  if (!node.parent) {
    return undefined;
  }
  const parent = node.parent;
  const nthSibling = getSiblingIndex(parent, node);
  if (nthSibling < parent.children.length - 1) {
    return parent?.children[nthSibling + 1].id;
  }
}
export function getWords(start?: { content: string }) {
  if (!start) {
    return [];
  }

  const words = start.content.replaceAll(/\[[^\]]*\]\([^)]*\)/g, "").split(/\s+/);

  return Array.from(new Set(words).values());
}

export function getWordsFromTree(start?: TreeNode) {
  if (!start) {
    return [];
  }

  const words = new Set<string>();
  for (const node of flattenBreadthFirst(start)) {
    getWords(node).forEach((word) => words.add(word));
  }

  return Array.from(words.values());
}

function isTag(word: string) {
  return (word.startsWith("#") || word.startsWith("@")) && word.length >= 2;
}

export function getTagsFromTree(node?: TreeNode) {
  return getWordsFromTree(node).filter(isTag).sort();
}

export function getTags(node?: TreeNode) {
  return getWords(node).filter(isTag).sort();
}

export function removeTags(content: string): string {
  return content
    .split(" ")
    .filter((word) => !isTag(word))
    .join(" ");
}

export function getAncestors(node: TreeNode) {
  const ancestors: TreeNode[] = [];
  let current = node;
  while (current.parent) {
    ancestors.push(current.parent);
    current = current.parent;
  }
  ancestors.reverse();

  return ancestors;
}

export function isLeafNode(n: TreeNode) {
  return n.children.length === 0;
}

export function withoutChecked(node: TreeNode): TreeNode {
  return {
    ...node,
    children: node.children.filter((n) => !n.checked).map(withoutChecked),
  };
}

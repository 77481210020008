import React from "react";

import { useFixedNodeMapKeys } from "./useFixedNodeMapKeys";
import { ToolboxRevealOnHover } from "./ToolboxRevealOnHover";
import { DynalistDocument, TreeNode } from "../../service/dynalist/document/DynalistDocument";
import { ToolBox } from "./ToolBox";
import { NodeAction, TooltipAndActionNodeContent } from "./TooltipAndActionNodeContent";

function NodeTreePartial({
  document,
  nodes,
  action,
}: {
  document: DynalistDocument;
  nodes: TreeNode[];
  action?: NodeAction;
}) {
  const fixedNodeMapKeys = useFixedNodeMapKeys(document);

  return (
    <ul>
      {nodes.map((node) => (
        <li key={fixedNodeMapKeys.getFixedNodeKey(node.id)}>
          <ToolboxRevealOnHover toolbox={<ToolBox document={document} nodeId={node.id} />}>
            <TooltipAndActionNodeContent node={node} document={document} action={action} />
          </ToolboxRevealOnHover>
          {node.children.length > 0 && <NodeTreePartial document={document} nodes={node.children} action={action} />}
        </li>
      ))}
    </ul>
  );
}

export function NodeTree({
  document,
  nodes,
  action,
}: {
  document: DynalistDocument;
  nodes: TreeNode[];
  action?: NodeAction;
}) {
  return (
    <div>
      <NodeTreePartial document={document} nodes={nodes} action={action} />
    </div>
  );
}

import moment from "moment";

import { Month, monthToMoment } from "./Month";
import { Day } from "./Day";
import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { flattenDepthFirst } from "../../../service/dynalist/nodes";

const regExp = /@bujomonth:(\w+)/;

export interface BujoMonth {
  nodeId: string;
  name: string;
}

export function getBujoMonths(document: DynalistDocument): BujoMonth[] {
  return flattenDepthFirst(document.getRootNode())
    .filter((node) => regExp.test(node.content))
    .map((node) => {
      const match = node.content.match(regExp)!;

      return {
        nodeId: node.id,
        name: match[1],
      };
    });
}

export function getBujoMonthDays(document: DynalistDocument, bujoMonth: BujoMonth, month: Month): Day[] {
  const allNodes = flattenDepthFirst(document.getNode(bujoMonth.nodeId)!);
  const monthPrefix = monthToMoment(month).format("YYYY-MM-");
  const thisMonthNodes = allNodes.filter((n) => n.content.startsWith(monthPrefix));

  function createDayEntry(year: number, month: number, day: number) {
    const m = moment()
      .year(year)
      .month(month - 1)
      .date(day);

    const node: TreeNode | undefined = thisMonthNodes.find((node) => node.content.startsWith(m.format("YYYY-MM-DD ")));

    return {
      year,
      month,
      day,
      node,
      bujoMonth,
    };
  }

  return [...Array(monthToMoment(month).daysInMonth())].map((_, zeroBasedDay) => {
    const year = month.year;
    const m = month.month;
    const day = zeroBasedDay + 1;

    return createDayEntry(year, m, day);
  });
}

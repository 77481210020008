import React, { ReactNode, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";

import { Loading } from "./Loading";
import { InlineIconButton } from "./InlineIconButton";

export function AButtonThatFuckingDoesImportantThings({
  ariaLabel,
  children,
  importantThings,
  waitingLabel,
}: {
  ariaLabel: string;
  children: ReactNode;
  importantThings: () => Promise<void>;
  waitingLabel: string;
}) {
  const [isUiDisabled, setIsUiDisabled] = useState(false);

  function disableUi() {
    return setIsUiDisabled(true);
  }

  function enableUi() {
    return setIsUiDisabled(false);
  }

  async function refresh() {
    disableUi();
    await importantThings();
    enableUi();
  }

  return (
    <>
      <InlineIconButton ariaLabel={ariaLabel} onClick={refresh}>
        {children}
      </InlineIconButton>

      <Modal show={isUiDisabled} onHide={disableUi}>
        <Row className="mt-4">
          <Col></Col>
          <Col className="justify-content-center" xs="auto" style={{ textAlign: "center" }}>
            <div className="mt-3 mb-3">{waitingLabel}</div>
            <Loading />
          </Col>
          <Col></Col>
        </Row>
      </Modal>
    </>
  );
}

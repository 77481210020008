import { DynalistDocument, TreeNode } from "./dynalist/document/DynalistDocument";

function getNode(document: DynalistDocument, regExp: RegExp, msg: string) {
  const node = document.getRootNode().children.find((node) => node.content.match(regExp));
  if (!node) {
    throw new Error(msg + " not found");
  }

  return node;
}

export function getDayPlansNode(document: DynalistDocument): TreeNode {
  return getNode(document, /^day plans/i, "Day plans");
}

export function getProjectsNode(document: DynalistDocument): TreeNode {
  return getNode(document, /^projects/i, "Projects");
}

import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import "./LinkButton.scss";

export function LinkButton({
  className = "",
  url,
  children,
}: {
  url: string;
  children: ReactNode;
  className?: string;
}) {
  const navigate = useNavigate();

  return (
    <button
      className={`link-button ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        navigate(url);
      }}
    >
      {children}
    </button>
  );
}

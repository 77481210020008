import moment from "moment";

export type Month = { month: number; year: number };

export function monthToMoment(month: Month): moment.Moment {
  return moment()
    .year(month.year)
    .month(month.month - 1);
}

function momentToMonth(moment: moment.Moment): Month {
  return { year: moment.year(), month: moment.month() + 1 };
}

export function previousMonth(month: Month): Month {
  const previousMonth = monthToMoment(month).subtract(1, "month");

  return momentToMonth(previousMonth);
}

export function nextMonth(month: Month): Month {
  const nextMonth = monthToMoment(month).add(1, "month");

  return momentToMonth(nextMonth);
}

import { ListGroup } from "react-bootstrap";
import React from "react";

import { DynalistDocument } from "../../../service/dynalist/document/DynalistDocument";
import { Mode, TgaaTargetsOrLatestProjects } from "../../component/TgaaTargetsOrLatestProjects";

export function LatestProjectsPage({ document }: { document: DynalistDocument }) {
  return (
    <ListGroup variant="flush">
      <TgaaTargetsOrLatestProjects document={document} modes={[Mode.listGroupLatestProjects]} />
    </ListGroup>
  );
}

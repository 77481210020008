import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { DocumentNodeRouteSpec, DocumentRouteSpec, Routes, RouteSpec } from "./route";
import { ListDocumentsPage } from "./view/noparam/home/ListDocumentsPage";
import { CalendarViewPage } from "./view/documentnode/calendarview/CalendarViewPage";
import { BujoMonthsPage } from "./view/document/bujomonths/BujoMonthsPage";
import { CategorizePage } from "./view/documentnode/categorize/CategorizePage";
import { KanbanPage } from "./view/documentnode/kanban/KanbanPage";
import { LatestProjectsPage } from "./view/documentnode/latestprojects/LatestProjectsPage";
import { NodePage } from "./view/documentnode/node/NodePage";
import { PlanPage } from "./view/documentnode/plan/PlanPage";
import { SizesViewPage } from "./view/documentnode/sizes/SizesViewPage";
import { TagsPage } from "./view/documentnode/tags/TagsPage";
import { useDocumentTitle } from "./view/component/useDocumentTitle";
import { MainContainerWithHeader, MainContainerWithHeaderMode } from "./view/component/MainContainerWithHeader";
import { useDynalistDocument, useUpdateUrlOnPermanentIdChange } from "./view/component/documentHooks";
import { Loading } from "./view/component/Loading";
import { MainContainer } from "./view/component/MainContainer";
import { TrackerPointOfSalePage } from "./view/document/trackerpos/TrackerPointOfSalePage";
import { DynalistDocument, TreeNode } from "./service/dynalist/document/DynalistDocument";
import { DocumentMenuPage } from "./view/document/documentmenu/DocumentMenuPage";
import { BumpProject } from "./view/document/bumpproject/BumpProject";
import { AddPageFromParams } from "./view/document/documentmenu/add/AddPage";

function DocumentNodePageWithMainContainer({
  document,
  node,
  page,
  routeSpec,
  opts,
}: {
  document: DynalistDocument;
  node: TreeNode;
  page: React.FC<{ document: DynalistDocument; node: TreeNode }>;
  routeSpec: DocumentNodeRouteSpec;
  opts?: Opts;
}) {
  useDocumentTitle(`${routeSpec.title}: ${node.content.substring(0, 80)}`);

  return (
    <MainContainerWithHeader
      document={document}
      node={node}
      routeSpec={routeSpec}
      className={opts?.className}
      mode={opts?.mode}
    >
      {React.createElement(page, { document, node }, null)}
    </MainContainerWithHeader>
  );
}

function DocumentPageWithMainContainer({
  document,
  page,
  routeSpec,
  opts,
}: {
  document: DynalistDocument;
  page: React.FC<{ document: DynalistDocument }>;
  routeSpec: DocumentNodeRouteSpec;
  opts?: Opts;
}) {
  useDocumentTitle(`${routeSpec.title}`);

  return (
    <MainContainerWithHeader document={document} routeSpec={routeSpec} className={opts?.className} mode={opts?.mode}>
      {React.createElement(page, { document }, null)}
    </MainContainerWithHeader>
  );
}

function DocumentIdNodeIdPageWithMainContainer({
  documentId,
  nodeId,
  page,
  routeSpec,
  opts,
}: {
  documentId: string;
  nodeId: string;
  page: React.FC<{ document: DynalistDocument; node: TreeNode }>;
  routeSpec: DocumentNodeRouteSpec;
  opts?: Opts;
}) {
  const document = useDynalistDocument(documentId);
  useUpdateUrlOnPermanentIdChange(document, nodeId, routeSpec);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [nodeId]);

  if (!document) {
    return <Loading />;
  }

  const node = document.getNode(nodeId);
  if (!node) {
    return (
      <MainContainer>
        <p>Node id '{nodeId}' not present</p>
      </MainContainer>
    );
  }

  return (
    <DocumentNodePageWithMainContainer document={document} node={node} page={page} routeSpec={routeSpec} opts={opts} />
  );
}

function DocumentIdPageWithMainContainer({
  documentId,
  page,
  routeSpec,
  opts,
}: {
  documentId: string;
  page: React.FC<{ document: DynalistDocument }>;
  routeSpec: DocumentNodeRouteSpec;
  opts?: Opts;
}) {
  const document = useDynalistDocument(documentId);

  if (!document) {
    return <Loading />;
  }

  return <DocumentPageWithMainContainer document={document} page={page} routeSpec={routeSpec} opts={opts} />;
}

export function DocumentIdNodeIdPageWithMainContainerFromParams({
  page,
  routeSpec,
  opts,
}: {
  page: React.FC<{ document: DynalistDocument; node: TreeNode }>;
  routeSpec: DocumentNodeRouteSpec;
  opts?: Opts;
}) {
  const { documentId, nodeId } = useParams();

  if (!documentId || !nodeId) {
    return (
      <MainContainer>
        <p>Not all parameters present</p>
      </MainContainer>
    );
  }

  return (
    <DocumentIdNodeIdPageWithMainContainer
      documentId={documentId}
      nodeId={nodeId}
      page={page}
      routeSpec={routeSpec}
      opts={opts}
    />
  );
}

export function DocumentIdPageWithMainContainerFromParams({
  page,
  routeSpec,
  opts,
}: {
  page: React.FC<{ document: DynalistDocument }>;
  routeSpec: DocumentNodeRouteSpec;
  opts?: Opts;
}) {
  const { documentId } = useParams();

  if (!documentId) {
    return (
      <MainContainer>
        <p>Not all parameters present</p>
      </MainContainer>
    );
  }

  return <DocumentIdPageWithMainContainer documentId={documentId} page={page} routeSpec={routeSpec} opts={opts} />;
}

interface Opts {
  className?: string;
  mode?: MainContainerWithHeaderMode;
}

function createDocumentNodePageWithMainContainer(
  spec: DocumentNodeRouteSpec,
  page: React.FC<{ document: DynalistDocument; node: TreeNode }>,
  opts?: Opts
) {
  return {
    spec,
    element: <DocumentIdNodeIdPageWithMainContainerFromParams routeSpec={spec} page={page} opts={opts} />,
  };
}

function createDocumentPageWithMainContainer(
  spec: DocumentRouteSpec,
  page: React.FC<{ document: DynalistDocument }>,
  opts?: Opts
) {
  return {
    spec,
    element: <DocumentIdPageWithMainContainerFromParams routeSpec={spec} page={page} opts={opts} />,
  };
}

// This is separated to own file to avoid cyclic dependency that React can't handle
const routeToElementSpec: { spec: RouteSpec; element: React.ReactNode }[] = [
  { spec: Routes.getHome, element: <ListDocumentsPage /> },

  // Both documentId & nodeId pages
  createDocumentNodePageWithMainContainer(Routes.getCalendarView, CalendarViewPage),
  createDocumentNodePageWithMainContainer(Routes.getCategorize, CategorizePage, { mode: "bottomleft" }),
  createDocumentNodePageWithMainContainer(Routes.getKanban, KanbanPage, { className: "kanban", mode: "wide" }),
  createDocumentNodePageWithMainContainer(Routes.getNode, NodePage),
  createDocumentNodePageWithMainContainer(Routes.getPlan, PlanPage, { className: "plan-page" }),
  createDocumentNodePageWithMainContainer(Routes.getSizesView, SizesViewPage),
  createDocumentNodePageWithMainContainer(Routes.getTags, TagsPage),

  // Non nodeId pages
  createDocumentPageWithMainContainer(Routes.getLatestProjects, LatestProjectsPage),
  createDocumentPageWithMainContainer(Routes.getTrackerPointOfSale, TrackerPointOfSalePage),
  createDocumentPageWithMainContainer(Routes.getBujoMonths, BujoMonthsPage),
  createDocumentPageWithMainContainer(Routes.getBumpProject, BumpProject),
  createDocumentPageWithMainContainer(Routes.getDocumentMenu, DocumentMenuPage),
  {
    spec: Routes.getAdd,
    element: <AddPageFromParams />,
  },
];

export function getElement(spec: RouteSpec) {
  const route = routeToElementSpec.find((route) => route.spec === spec);

  if (!route) {
    throw new Error(`Could not find route for ${spec.pathFn}`);
  }

  return route.element;
}

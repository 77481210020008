import { Change, DynalistClient, MakeChangesResponse } from "./DynalistClient";

const apiBaseUrl = "https://dynalist.io/api/v1/";

class DynalistJS {
  private token: any;

  constructor(token: any) {
    this.token = token;
  }

  listFiles() {
    return this.makeRequest("file/list", {});
  }

  editFile(changes: any) {
    const data = {
      changes,
    };

    return this.makeRequest("file/edit", data);
  }

  readDocument(file_id: any) {
    const data = {
      file_id,
    };

    return this.makeRequest("doc/read", data);
  }

  checkForDocumentUpdates(file_ids: any) {
    const data = {
      file_ids,
    };

    return this.makeRequest("doc/check_for_updates", data);
  }

  editDocument(file_id: any, changes: any) {
    const data = {
      file_id,
      changes,
    };

    return this.makeRequest("doc/edit", data);
  }

  sendToInbox(content: any) {
    return this.makeRequest("inbox/add", { content });
  }

  private makeRequest(endpoint: any, data: any) {
    return fetch(apiBaseUrl + endpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        token: this.token,
        ...data,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response._code !== "Ok") {
          throw new Error(JSON.stringify(response));
        }

        return response;
      });
  }
}

export class HttpDynalistClient implements DynalistClient {
  private dynalistjs: any;

  constructor(dynalistAccessToken: string) {
    this.dynalistjs = new DynalistJS(dynalistAccessToken);
  }

  listFiles(): Promise<any> {
    return this.dynalistjs.listFiles();
  }

  readDocument(documentId: string): Promise<any> {
    return this.dynalistjs.readDocument(documentId);
  }

  editDocument(documentId: string, changes: Change[]): Promise<MakeChangesResponse> {
    return this.dynalistjs.editDocument(documentId, changes);
  }
}

import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";

import { getRuntimeDeps } from "../../../runtimeDeps";
import { File } from "../../../service/dynalist/documentstorage/DynalistDocumentStorage";
import { Routes } from "../../../route";
import { Loading } from "../../component/Loading";
import { MainContainer } from "../../component/MainContainer";
import { ListGroupItemLink } from "../../component/ListGroupItemLink";

export function ListDocumentsPage() {
  const [documents, setDocuments] = useState<File[]>();
  useEffect(() => {
    getRuntimeDeps().dynalistDocumentStorage.getDocuments().then(setDocuments);
  }, []);

  if (!documents) {
    return <Loading />;
  }

  return (
    <MainContainer>
      <p>Documents</p>
      <ListGroup variant="flush">
        {documents.map((file) => (
          <ListGroupItemLink key={file.id} to={Routes.getNode.pathFn(file.id, "root")}>
            {file.title}
          </ListGroupItemLink>
        ))}
      </ListGroup>
    </MainContainer>
  );
}

import React, { useEffect, useRef } from "react";

import "./NicerContentFittingTextArea.scss";

export function NicerContentFittingTextArea(
  props: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
) {
  const ref = useRef<HTMLTextAreaElement>(null);

  function setHeight() {
    if (ref.current) {
      ref.current.style.height = ref.current.scrollHeight + "px";
    }
  }

  useEffect(() => {
    setHeight();
  });

  return (
    <textarea
      ref={ref}
      {...{ ...props, className: (props.className || "") + " nicer-content-fitting-text-area" }}
      rows={1}
    />
  );
}

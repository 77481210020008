import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { Routes } from "../../../route";
import { useFixedNodeMapKeys } from "../../component/useFixedNodeMapKeys";
import { NodeContent } from "../../component/NodeContent";
import { TrashButton } from "../../component/TrashButton";

import "./KanbanPage.scss";

export function KanbanPage({ document, node }: { document: DynalistDocument; node: TreeNode }) {
  const fixedNodeMapKeys = useFixedNodeMapKeys(document);

  const navigate = useNavigate();

  return (
    <>
      <Row className="mb-2">
        <Col>
          <div className="kanban__heading">
            <NodeContent node={node} document={document} />
            <TrashButton
              document={document}
              nodeId={node.id}
              nextFocusAdviceCallback={(nextFocusNodeId) =>
                navigate(Routes.getKanban.pathFn(document.getDocumentId(), nextFocusNodeId))
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        {node.children.map((node) => (
          <Col key={fixedNodeMapKeys.getFixedNodeKey(node.id)} className="mb-5 kanban__column">
            <Link to={Routes.getKanban.pathFn(document.getDocumentId(), node.id)}>
              <div className="kanban__column-heading">
                <NodeContent node={node} document={document} />
              </div>
            </Link>
            {/* Following empty div is used as makeshift clearfix */}
            <div></div>
            {node.children.map((node) => (
              <Link
                key={fixedNodeMapKeys.getFixedNodeKey(node.id)}
                to={Routes.getKanban.pathFn(document.getDocumentId(), node.id)}
                style={{ color: "inherit", textDecoration: "none" }}
                className={`kanban__card kanban__card--has-${Math.min(node.children.length, 3)}-subcards`}
              >
                <NodeContent node={node} document={document} />
              </Link>
            ))}
          </Col>
        ))}
      </Row>
    </>
  );
}

import React, { useEffect, useState } from "react";

import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { NodeContent } from "../../component/NodeContent";
import { ContentFittingTextArea } from "../../component/ContentFittingTextArea";

import "./EditableNodeContent.scss";

function noOpContent(content: string) {
  return content;
}

function noOp() {}

export function EditableNodeContent({
  document,
  node,
  className = "",
  beforeRender = noOpContent,
  beforeSave = noOpContent,
  openInEditMode = false,
  onSaveComplete = noOp,
}: {
  document: DynalistDocument;
  node: TreeNode;
  className?: string;
  beforeRender?(content: string): string;
  beforeSave?(content: string): string;
  openInEditMode?: boolean;
  onSaveComplete?(): void;
}) {
  const [content, setContent] = useState(beforeRender(node.content));
  const [editContent, setEditContent] = useState(openInEditMode);

  useEffect(() => {
    setContent(beforeRender(node.content));
  }, [beforeRender, node.content]);

  function hideAndSave() {
    setEditContent(false);
    document.edit(node.id, { content: beforeSave(content) });
    onSaveComplete();
  }

  return editContent ? (
    <form
      className={`editable-node-content__form ${className}`}
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        hideAndSave();
      }}
    >
      <ContentFittingTextArea
        autoFocus
        className="editable-node-content__textarea"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        onBlur={hideAndSave}
      />
    </form>
  ) : (
    <div onClick={() => setEditContent(true)} className={`editable-node-content__content ${className}`}>
      <NodeContent document={document} node={node} preProcessContent={beforeRender} />
    </div>
  );
}

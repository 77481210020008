import { Container } from "react-bootstrap";
import React, { useState } from "react";

import { setDynalistAccessToken } from "./localStorage";

export function SetAccessToken() {
  const [token, setToken] = useState("");
  const submitValue = "Submit";

  return (
    <Container>
      <p>
        Get your Dynalist Developer API secret from{" "}
        <a href="https://dynalist.io/developer">https://dynalist.io/developer</a> and copy & paste it here.
      </p>
      <form
        action=""
        onSubmit={() => {
          setDynalistAccessToken(token);
          window.location.reload();
        }}
      >
        <input value={token} onChange={(e) => setToken(e.target.value)} />
        <input type="submit" disabled={token === ""} value={submitValue} className="ms-2" />
      </form>
    </Container>
  );
}

import { DynalistDocument, TreeNode } from "../service/dynalist/document/DynalistDocument";
import { cloneDeep, flattenDepthFirst, getAncestors, getTags } from "../service/dynalist/nodes";
import { sortByNestedModifiedOrPriority } from "./sort";

const controlTags = ["tg", "ignore", "pos"] as const;
type ControlTag = (typeof controlTags)[number];

function hitsControlTag(node: TreeNode | undefined, controlTag: ControlTag) {
  if (!node) {
    return false;
  }
  const isAt = getTags(node).includes("@" + controlTag);
  const isAtA = getTags(node).includes("@" + controlTag + "a");
  const isAtAa = getTags(node).includes("@" + controlTag + "aa");
  const isChildOfAtA = getTags(node.parent).includes("@" + controlTag + "a");
  const isChildOfAtAa = getTags(node.parent).includes("@" + controlTag + "aa");
  const isGrandChildOfAa = getTags(node.parent?.parent).includes("@" + controlTag + "aa");

  return isAt || isAtA || isAtAa || isChildOfAtA || isChildOfAtAa || isGrandChildOfAa;
}

export function getControlTagTree(document: DynalistDocument, controlTag: ControlTag) {
  type EarmarkedTreeNode = TreeNode & { include?: boolean };

  const clonedRootNode = cloneDeep(document.getRootNode());
  // remove Trash
  clonedRootNode.children = clonedRootNode.children.filter((n) => n.content !== "Trash");
  const flattened: EarmarkedTreeNode[] = flattenDepthFirst(clonedRootNode);

  function markNodeAndAncestorsIncluded(node: EarmarkedTreeNode) {
    let current: EarmarkedTreeNode | undefined = node;
    while (current) {
      current.include = true;
      current = current.parent;
    }
  }

  for (const node of flattened) {
    if (hitsControlTag(node, controlTag)) {
      markNodeAndAncestorsIncluded(node);
    }
  }

  function getIncluded(node: EarmarkedTreeNode): TreeNode[] {
    if (node.include) {
      return [
        {
          ...node,
          children: node.children.flatMap(getIncluded),
        },
      ];
    } else {
      return [];
    }
  }

  return getIncluded(clonedRootNode)[0]?.children || [];
}

function withoutTrash(root: TreeNode) {
  return {
    ...root,
    children: root.children.filter((n) => n.content !== "Trash"),
  };
}

export function _getReasonableControlTagNodes(document: DynalistDocument, controlTag: ControlTag) {
  return flattenDepthFirst(withoutTrash(document.getRootNode())).filter((n) => hitsControlTag(n, controlTag));
}

export function _enrichReasonableAncestors(reasonableControlTagNodes: TreeNode[]) {
  function getReasonableAncestors(child: TreeNode) {
    const result: TreeNode[] = [];

    for (const ancestor of getAncestors(child).reverse()) {
      if (hitsControlTag(ancestor, "ignore")) {
        break;
      }
      result.push(ancestor);
    }

    return result.reverse();
  }

  return reasonableControlTagNodes.map((node) => ({
    node,
    reasonableAncestors: getReasonableAncestors(node),
  }));
}

export interface ReasonableControlTagNode {
  node: TreeNode;
  reasonableAncestors: TreeNode[];
}

export function getReasonableControlTagNodes(
  document: DynalistDocument,
  controlTag: ControlTag
): ReasonableControlTagNode[] {
  const reasonableControlTagNodes = _getReasonableControlTagNodes(document, controlTag);

  function isPartOfReasonableNodes(node: TreeNode) {
    return reasonableControlTagNodes.some(({ id }) => id === node.id);
  }

  return _enrichReasonableAncestors(sortByNestedModifiedOrPriority(reasonableControlTagNodes, isPartOfReasonableNodes));
}

export function removeControlTags(content: string) {
  return (" " + content + " ").replace(new RegExp(`[\\s]+@(${controlTags.join("|")})[a]*[\\s]+`), " ").trim();
}

export function formatReasonableName(reasonableControlTagNode: ReasonableControlTagNode) {
  return [...reasonableControlTagNode.reasonableAncestors, reasonableControlTagNode.node]
    .map((node) => node.content)
    .map((content) => removeControlTags(content))
    .join(" → ");
}

const DYNALIST_ACCESS_TOKEN_KEY = "DYNALIST_ACCESS_TOKEN";

export class DynalistAccessTokenNotSetError extends Error {}

export function getDynalistAccessToken(): string {
  const accessToken = localStorage.getItem(DYNALIST_ACCESS_TOKEN_KEY);
  if (!accessToken) {
    throw new DynalistAccessTokenNotSetError(`Couldn't get Dynalist access token. Set token with: 
 localStorage.setItem("${DYNALIST_ACCESS_TOKEN_KEY}", "<your key>")`);
  }

  return accessToken;
}

export function setDynalistAccessToken(token: string) {
  localStorage.setItem(DYNALIST_ACCESS_TOKEN_KEY, token);
}

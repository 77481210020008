import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { BiCalendar, BiLastPage, BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { useDocumentChangeListener } from "../../component/documentHooks";
import { NodeContent } from "../../component/NodeContent";
import { InlineIconButton } from "../../component/InlineIconButton";
import { TrashButton } from "../../component/TrashButton";
import { Routes } from "../../../route";
import { NodeTree } from "../../component/NodeTree";
import { TgaaTargetsOrLatestProjects } from "../../component/TgaaTargetsOrLatestProjects";
import { ResponsiveLeftSqueezeRightScrollableLayout } from "../../component/ResponsiveLeftSqueezeRightScrollableLayout";
import { withoutChecked } from "../../../service/dynalist/nodes";

import "./CategorizePage.scss";

function moveToJournal(document: DynalistDocument, node: TreeNode) {
  const targetNodeContent = moment(node.created).format("YYYY-MM-DD");

  function getTargetNode() {
    return document.getRootNode().children.find((node) => node.content === targetNodeContent);
  }

  const targetNode = getTargetNode();
  if (!targetNode) {
    const targetIndex = node.parent?.id === "root" ? node.parent.children.indexOf(node) : 0;
    document.insert(targetNodeContent, "root", targetIndex);
  }
  document.move(node.id, getTargetNode()!.id, 0);
}

export function CategorizePage({ document, node }: { document: DynalistDocument; node: TreeNode }) {
  node = withoutChecked(node);

  const navigate = useNavigate();
  const [indexUnderCategorization, setIndexUnderCategorization] = useState(0);

  // workaround to navigation route re-using same component, couldn't figure out better solution that would work
  useEffect(() => {
    setIndexUnderCategorization(0);
  }, [document, node.id]);

  useDocumentChangeListener(document, () => {
    setIndexUnderCategorization((i) => Math.min(i, document.getNode(node.id)!.children.length - 1));
  });

  const nodeUnderCategorization = node.children[indexUnderCategorization];
  const lastIndex = node.children.length - 1;

  return (
    <ResponsiveLeftSqueezeRightScrollableLayout
      left={
        <>
          <Col className="mb-3">
            <NodeContent node={node} document={document} />
          </Col>
          {node.children.length > 0 ? (
            <>
              <Row className="align-items-center mb-2">
                <Col>
                  <div className="categorize-page__ith_of_total">
                    {indexUnderCategorization + 1} / {node.children.length}
                  </div>
                </Col>
                <Col xs="auto">
                  <div>
                    <InlineIconButton
                      ariaLabel="Previous child"
                      onClick={() => setIndexUnderCategorization((i) => i - 1)}
                      disabled={indexUnderCategorization === 0}
                    >
                      <BiLeftArrowAlt />
                    </InlineIconButton>
                    <InlineIconButton
                      ariaLabel="Journal"
                      onClick={() => moveToJournal(document, nodeUnderCategorization)}
                    >
                      <BiCalendar />
                    </InlineIconButton>
                    <TrashButton
                      document={document}
                      nodeId={nodeUnderCategorization.id}
                      nextFocusAdviceCallback={(nextFocusNodeId) => {
                        const nextFocusNode = document.getNode(nextFocusNodeId)!;
                        const focusNodeHasSameParentAsCurrentPage = nextFocusNode?.parent?.id === node.id;
                        if (focusNodeHasSameParentAsCurrentPage) {
                          const nextFocusIndex = nextFocusNode.parent!.children.indexOf(nextFocusNode);
                          setIndexUnderCategorization(nextFocusIndex);
                        } else {
                          // TODO: navigate to indexOf the node, requires moving n-th index to url param, now its in state only
                          const parentOfParent = nextFocusNode.parent;
                          if (!parentOfParent) {
                            throw new Error("Dunno whattodo");
                          }
                          navigate(Routes.getCategorize.pathFn(document.getDocumentId(), parentOfParent.id));
                        }
                      }}
                    />
                    <InlineIconButton
                      ariaLabel="Next child"
                      onClick={() => setIndexUnderCategorization((i) => i + 1)}
                      disabled={indexUnderCategorization >= lastIndex}
                    >
                      <BiRightArrowAlt />
                    </InlineIconButton>
                    <InlineIconButton
                      ariaLabel="Last child"
                      onClick={() => setIndexUnderCategorization(lastIndex)}
                      disabled={indexUnderCategorization >= lastIndex}
                    >
                      <BiLastPage />
                    </InlineIconButton>
                  </div>
                </Col>
              </Row>

              {nodeUnderCategorization && (
                <NodeTree
                  document={document}
                  nodes={[nodeUnderCategorization]}
                  action={{
                    onClick(node: TreeNode) {
                      navigate(Routes.getCategorize.pathFn(document.getDocumentId(), node.id));
                    },
                    tooltip: "Zoom In",
                    cursor: "pointer",
                  }}
                />
              )}
            </>
          ) : (
            <div className="categorize-page__no_items_to_categorize">No items to categorize</div>
          )}
        </>
      }
      right={
        <TgaaTargetsOrLatestProjects
          document={document}
          action={{
            onClick(node: TreeNode) {
              document.move(nodeUnderCategorization.id, node.id, 0);
            },
            tooltip: "Send To",
            cursor: "pointer",
          }}
        />
      }
    />
  );
}

import { TreeNode } from "../service/dynalist/document/DynalistDocument";
import { getTags } from "./dynalist/nodes";

export function sortByNestedModifiedOrPriority(nodes: TreeNode[], skip: (node: TreeNode) => boolean = () => false) {
  const cachedModifieds = new Map<TreeNode, number>();

  function doGetModified(n: TreeNode) {
    return n.children.reduce((acc, child) => Math.max(acc, skip(child) ? acc : getModified(child)), n.modified);
  }

  function getModified(n: TreeNode): number {
    if (!cachedModifieds.has(n)) {
      cachedModifieds.set(n, doGetModified(n));
    }

    return cachedModifieds.get(n)!;
  }

  return nodes.sort((a, b) => {
    if (getTags(a).includes("@1st") && getTags(b).includes("@1st")) {
      return a.content.localeCompare(b.content);
    }

    function getOffsetForSortPriority(node: TreeNode) {
      const tenYears = 10 * 365 * 24 * 60 * 60 * 1000;

      return getTags(node).includes("@1st") ? tenYears : 0;
    }

    return getOffsetForSortPriority(b) + getModified(b) - (getOffsetForSortPriority(a) + getModified(a));
  });
}

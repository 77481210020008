export function parseTimeInfoFromStart(string: string): { hour: number; minute: number } {
  const time = parseHHMMTimeString(string);

  if (!time) {
    throw new Error(`Unable to parse time: ${string}`);
  }

  return time;
}

export function parseDateTimeInfoFromStart(string: string): Date {
  const date =
    parseDMYYYYHHMMDateTimeString(string) ??
    parseYYYYMMDDHHMMDateTimeString(string) ??
    parseDMYYYYDateString(string) ??
    parseYYYYMMDDDateString(string);

  if (!date) {
    throw new Error(`Unable to parse date or date time: ${string}`);
  }

  return date;
}

function parseDateString(
  inputString: string,
  datePattern: RegExp,
  dayIndex: number,
  monthIndex: number,
  yearIndex: number
) {
  const match = inputString.match(datePattern);

  if (match) {
    const day = parseInt(match[dayIndex], 10);
    const month = parseInt(match[monthIndex], 10);
    const year = parseInt(match[yearIndex], 10);
    const hour = 0;
    const minute = 0;

    const date = new Date(year, month - 1, day, hour, minute);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }

  return null;
}

function parseDateTimeString(
  inputString: string,
  datePattern: RegExp,
  dayIndex: number,
  monthIndex: number,
  yearIndex: number
) {
  const match = inputString.match(datePattern);

  if (match) {
    const day = parseInt(match[dayIndex], 10);
    const month = parseInt(match[monthIndex], 10);
    const year = parseInt(match[yearIndex], 10);
    const hour = parseInt(match[4], 10);
    const minute = parseInt(match[5], 10);

    const date = new Date(year, month - 1, day, hour, minute);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }

  return null;
}

const datePatternDMYYYYHHMM = /^\s*(\d{1,2})\.(\d{1,2})\.(\d{4})\s+(\d{2}):(\d{2})(\s+|$)/;

function parseDMYYYYHHMMDateTimeString(inputString: string) {
  return parseDateTimeString(inputString, datePatternDMYYYYHHMM, 1, 2, 3);
}

const datePatternDMYYYY = /^\s*(\d{1,2})\.(\d{1,2})\.(\d{4})(\s+|$)/;

function parseDMYYYYDateString(inputString: string) {
  return parseDateString(inputString, datePatternDMYYYY, 1, 2, 3);
}

const datePatternYYYYMMDDHHMM = /^\s*(\d{4})-(\d{2})-(\d{2})\s+(\d{2}):(\d{2})(\s+|$)/;

function parseYYYYMMDDHHMMDateTimeString(inputString: string) {
  return parseDateTimeString(inputString, datePatternYYYYMMDDHHMM, 3, 2, 1);
}

const datePatternYYYYMMDD = /^\s*(\d{4})-(\d{2})-(\d{2})(\s+|$)/;

function parseYYYYMMDDDateString(inputString: string) {
  return parseDateString(inputString, datePatternYYYYMMDD, 3, 2, 1);
}

const timePatternHHMM = /^\s*(\d{2})[:.]?(\d{2})(\s+|$)/;
function parseHHMMTimeString(inputString: string) {
  const match = inputString.match(timePatternHHMM);

  if (match) {
    const hour = parseInt(match[1], 10);
    const minute = parseInt(match[2], 10);

    return {
      hour,
      minute,
    };
  }

  return null;
}

function removeMatchingRegexpFromString(inputString: string, regexp: RegExp) {
  return inputString.replace(regexp, "");
}

export function removeTimestampFromStart(string: string) {
  string = removeMatchingRegexpFromString(string, datePatternYYYYMMDDHHMM);
  string = removeMatchingRegexpFromString(string, datePatternDMYYYYHHMM);
  string = removeMatchingRegexpFromString(string, datePatternYYYYMMDD);
  string = removeMatchingRegexpFromString(string, datePatternDMYYYY);
  string = removeMatchingRegexpFromString(string, timePatternHHMM);

  return string;
}

export function withTime(date: Date, time: { hour: number; minute: number }) {
  const newDate = new Date(date);
  newDate.setHours(time.hour);
  newDate.setMinutes(time.minute);

  return newDate;
}

import Markdown from "markdown-to-jsx";
import React, { ReactNode } from "react";
import { BiDotsHorizontalRounded, BiLink } from "react-icons/bi";

import { LinkButton } from "./LinkButton";
import { DynalistDocument, TreeNode } from "../../service/dynalist/document/DynalistDocument";
import { containsDynalistLink, extractDynalistLink } from "../../service/dynalist/client/DynalistUrl";
import { Routes } from "../../route";
import { replacePriorityTagsWithIcons } from "../../service/pin";

import "./NodeContent.scss";

function noOp(content: string) {
  return content;
}

function Anchor({ children, href, document }: { children: ReactNode; href: string; document: DynalistDocument }) {
  if (containsDynalistLink(href)) {
    const { documentId, nodeId } = extractDynalistLink(href);

    const isTargetInSameDocument = document.getDocumentId() === documentId;
    const targetLabel = isTargetInSameDocument ? document.getNode(nodeId)?.content : "...";

    return (
      <LinkButton className="dynalist-internal-link" url={Routes.getNode.pathFn(documentId, nodeId)}>
        <BiLink />
        {targetLabel}
      </LinkButton>
    );
  } else {
    return <>{children}</>;
  }
}

function removeUnorthodoxMarkdown(node: TreeNode) {
  return node.content.replaceAll("__", "_");
}

const strikeThrough = {
  textDecoration: "line-through",
  textDecorationColor: "#333",
  textDecorationThickness: "1px",
};

export function NodeContent({
  document,
  node,
  preProcessContent = noOp,
  showChildrenIndicator = false,
}: {
  document: DynalistDocument;
  node: TreeNode;
  preProcessContent?(content: string): string;
  showChildrenIndicator?: boolean;
}) {
  let content = removeUnorthodoxMarkdown(node);
  content = replacePriorityTagsWithIcons(content);

  return (
    <>
      <Markdown
        options={{
          overrides: {
            a: {
              component: Anchor,
              props: { document },
            },
          },
        }}
        style={{
          ...(node.checked && strikeThrough),
        }}
      >
        {preProcessContent(content)}
      </Markdown>
      {showChildrenIndicator && document.getNode(node.id)!.children.length > 0 && (
        <BiDotsHorizontalRounded className="ms-2 text-secondary" />
      )}
    </>
  );
}

import React, { ChangeEvent } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { NavigateFunction } from "react-router/dist/lib/hooks";

import { DocumentNodeRouteSpec, Routes, RouteSpec } from "../../../route";
import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { buildUrl } from "../../../service/dynalist/client/DynalistUrl";

import "./DocumentNodeViewModeSelector.scss";

interface ViewModeSpec {
  title: string;
  isCurrentlySelected(currentRoute: RouteSpec): boolean;
  navigate(navigate: NavigateFunction, document: DynalistDocument, node: TreeNode): void;
}

function createInternalViewMode(routeSpec: DocumentNodeRouteSpec): ViewModeSpec {
  return {
    title: routeSpec.title,
    isCurrentlySelected(currentRoute: RouteSpec) {
      return currentRoute === routeSpec;
    },
    navigate(navigate, document, node) {
      return navigate(routeSpec.pathFn(document.getDocumentId(), node.id));
    },
  };
}

function createExternalViewMode(
  createTargetAddress: (document: DynalistDocument, node: TreeNode) => string,
  title: string
): ViewModeSpec {
  return {
    title,
    isCurrentlySelected() {
      return false;
    },
    navigate(navigate, document, node) {
      window.location.href = createTargetAddress(document, node);
    },
  };
}

const viewModes: ViewModeSpec[] = [
  createExternalViewMode(
    (document: DynalistDocument, node: TreeNode) => buildUrl(document.getDocumentId(), node.id),
    "Edit"
  ),
  createInternalViewMode(Routes.getKanban),
  createInternalViewMode(Routes.getNode),
  createInternalViewMode(Routes.getPlan),
  createInternalViewMode(Routes.getTags),
  createInternalViewMode(Routes.getCategorize),
  createInternalViewMode(Routes.getCalendarView),
  createInternalViewMode(Routes.getSizesView),
];

function getCurrentViewMode(currentRoute: DocumentNodeRouteSpec): ViewModeSpec {
  const viewModeSpec = viewModes.find((viewMode) => viewMode.isCurrentlySelected(currentRoute));
  if (!viewModeSpec) {
    throw new Error("Could not determine current view mode");
  }

  return viewModeSpec;
}

function DocumentNodeViewModeSelectComponent({
  document,
  node,
  currentRoute,
}: {
  document: DynalistDocument;
  node: TreeNode;
  currentRoute: DocumentNodeRouteSpec;
}) {
  const navigate = useNavigate();

  function onChange(e: ChangeEvent<HTMLSelectElement>) {
    const viewModeIndex = Number(e.currentTarget.value);
    const viewMode = viewModes[viewModeIndex];
    viewMode.navigate(navigate, document, node);
  }

  return (
    <Form.Select
      className="view-mode-selector--select"
      onChange={onChange}
      value={viewModes.indexOf(getCurrentViewMode(currentRoute))}
    >
      {viewModes.map((viewMode, i) => (
        <option key={i} value={i}>
          {viewMode.title}
        </option>
      ))}
    </Form.Select>
  );
}

export function DocumentNodeViewModeSelector({
  document,
  node,
  currentRoute,
}: {
  document: DynalistDocument;
  node: TreeNode;
  currentRoute: DocumentNodeRouteSpec;
}) {
  return (
    <>
      <span className="me-2">View:</span>
      <DocumentNodeViewModeSelectComponent document={document} node={node} currentRoute={currentRoute} />
    </>
  );
}

import React, { ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BiHomeAlt, BiLeftArrowAlt, BiRightArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

import { MainContainer } from "./MainContainer";
import { UndoButton } from "./UndoButton";
import { RefreshButton } from "./RefreshButton";
import { DocumentNodeViewModeSelector } from "./naviandview/DocumentNodeViewModeSelector";
import { DynalistDocument, TreeNode } from "../../service/dynalist/document/DynalistDocument";
import { DocumentNodePathFn, DocumentNodeRouteSpec, Routes } from "../../route";
import { getLeftSibling, getRightSibling } from "../../service/dynalist/nodes";

import "./MainContainerWithHeader.scss";

function NavigateLink({
  document,
  node,
  pathFn,
  getSiblingFn,
  children,
}: {
  document: DynalistDocument;
  node: TreeNode;
  pathFn: DocumentNodePathFn;
  getSiblingFn: (nodeId: TreeNode) => string | undefined;
  children: ReactNode;
}) {
  const targetNodeId = getSiblingFn(node);

  return (
    <span
      style={{
        width: "1.3em",
        display: "inline-block",
        textAlign: "center",
      }}
    >
      {targetNodeId ? (
        <Link to={pathFn(document.getDocumentId(), targetNodeId)}>{children}</Link>
      ) : (
        <span style={{ opacity: "0.1" }}>{children}</span>
      )}
    </span>
  );
}

export function NavigateToLeftSibling({
  document,
  node,
  pathFn,
}: {
  document: DynalistDocument;
  node: TreeNode;
  pathFn: DocumentNodePathFn;
}) {
  return (
    <NavigateLink document={document} node={node} pathFn={pathFn} getSiblingFn={getLeftSibling}>
      <BiLeftArrowAlt />
    </NavigateLink>
  );
}

export function NavigateToRightSibling({
  document,
  node,
  pathFn,
}: {
  document: DynalistDocument;
  node: TreeNode;
  pathFn: DocumentNodePathFn;
}) {
  return (
    <NavigateLink document={document} node={node} pathFn={pathFn} getSiblingFn={getRightSibling}>
      <BiRightArrowAlt />{" "}
    </NavigateLink>
  );
}

export function NavigateToParent({
  document,
  node,
  pathFn,
}: {
  document: DynalistDocument;
  node: TreeNode;
  pathFn: DocumentNodePathFn;
}) {
  function getParent(node: TreeNode) {
    return node.parent?.id;
  }

  return (
    <NavigateLink document={document} node={node} pathFn={pathFn} getSiblingFn={getParent}>
      <BiUpArrowAlt />
    </NavigateLink>
  );
}

function LeftParentRight({
  document,
  node,
  pathFn,
}: {
  document: DynalistDocument;
  node: TreeNode;
  pathFn: DocumentNodePathFn;
}) {
  return (
    <div className="mb-2">
      <NavigateToLeftSibling document={document} node={node} pathFn={pathFn} />
      <NavigateToParent document={document} node={node} pathFn={pathFn} />
      <NavigateToRightSibling document={document} node={node} pathFn={pathFn} />
    </div>
  );
}

export function Navigation({ document, compact }: { document: DynalistDocument; compact?: boolean }) {
  return (
    <div className={`navigation ${compact ? "navigation--compact" : ""}`}>
      <Link to={Routes.getHome.pathFn()} className="me-3">
        <BiHomeAlt />
      </Link>
      <Link to={Routes.getDocumentMenu.pathFn(document.getDocumentId())} className="me-3">
        Actions
      </Link>
      <span>
        <RefreshButton document={document} />
        <UndoButton document={document} />
      </span>
    </div>
  );
}

export type MainContainerWithHeaderMode = "normal" | "wide" | "bottomleft";

export function MainContainerWithHeader({
  children,
  document,
  node,
  routeSpec,
  className,
  mode = "normal",
}: {
  children: ReactNode;
  document: DynalistDocument;
  node?: TreeNode;
  routeSpec: DocumentNodeRouteSpec;
  className?: string;
  mode?: MainContainerWithHeaderMode;
}) {
  const navigation = <Navigation document={document} compact={mode === "bottomleft"} />;
  const leftParentRight = node ? <LeftParentRight document={document} node={node} pathFn={routeSpec.pathFn} /> : null;
  const documentNodeViewModeSelector = node ? (
    <DocumentNodeViewModeSelector document={document} node={node} currentRoute={routeSpec} />
  ) : null;

  const header = (
    <div className="header header--normal">
      <Container fluid={mode === "wide"}>
        <Row className="align-items-center">
          <Col>{navigation}</Col>
          {node && <Col xs="auto">{leftParentRight}</Col>}
          {node && <Col className="text-end">{documentNodeViewModeSelector}</Col>}
        </Row>
      </Container>
    </div>
  );

  const headerBottomLeft = (
    <div className="header header--bottom-left">
      <div>{navigation}</div>
      {node && <div>{leftParentRight}</div>}
      {node && <div>{documentNodeViewModeSelector}</div>}
    </div>
  );

  return (
    <div className={className}>
      {mode === "bottomleft" ? headerBottomLeft : header}
      <MainContainer label={routeSpec.title} children={children} fluid={mode === "wide" || mode === "bottomleft"} />
    </div>
  );
}

import React, { CSSProperties, useRef, useState } from "react";
import { Overlay } from "react-bootstrap";

import { DynalistDocument, TreeNode } from "../../service/dynalist/document/DynalistDocument";
import { NodeContent } from "./NodeContent";
import { removeTags } from "../../service/dynalist/nodes";

export interface NodeAction {
  onClick(node: TreeNode): void;

  tooltip: string;
  cursor: CSSProperties["cursor"];
}

export function TooltipAndActionNodeContent({
  document,
  node,
  action,
  showChildrenIndicator = false,
}: {
  node: TreeNode;
  document: DynalistDocument;
  action?: NodeAction;
  showChildrenIndicator?: boolean;
}) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const nodeContent = (
    <NodeContent
      node={node}
      document={document}
      preProcessContent={removeTags}
      showChildrenIndicator={showChildrenIndicator}
    />
  );

  if (action) {
    return (
      <>
        <div
          ref={target}
          onMouseOver={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            action.onClick(node);
          }}
          style={{ cursor: action.cursor }}
        >
          {nodeContent}
        </div>
        <Overlay target={target.current} show={show} placement="top-start">
          {({
            placement: _placement,
            arrowProps: _arrowProps,
            show: _show,
            popper: _popper,
            hasDoneInitialMeasure: _hasDoneInitialMeasure,
            ...props
          }) => (
            <div
              {...props}
              style={{
                position: "absolute",
                // backgroundColor: "rgba(255, 100, 100, 0.85)",
                // backgroundColor: "rgba(0, 0, 255, 0.06)",
                backgroundColor: "#3498db",
                padding: "2px 10px",
                color: "white",
                borderRadius: 3,
                ...props.style,
              }}
            >
              {action.tooltip}
            </div>
          )}
        </Overlay>
      </>
    );
  } else {
    return nodeContent;
  }
}

import { BiTrash } from "react-icons/bi";
import React from "react";

import { InlineIconButton } from "./InlineIconButton";
import { DynalistDocument, TreeNode } from "../../service/dynalist/document/DynalistDocument";

function getOrCreateTrash(document: DynalistDocument) {
  let trashId = document.getRootNode().children.find((child) => child.content.toLowerCase() === "trash")?.id;
  if (!trashId) {
    trashId = document.insert("Trash", "root", -1);
  }

  return trashId;
}

function getAdviceForNextFocus(document: DynalistDocument, node: TreeNode, indexBefore: number) {
  const parentAfter = document.getNode(node.parent!.id)!;
  if (indexBefore < parentAfter.children.length) {
    return parentAfter.children[indexBefore].id;
  } else if (indexBefore > 0) {
    return parentAfter.children[indexBefore - 1].id;
  } else {
    return parentAfter.id;
  }
}

export function deleteAndAdviseNextFocus(document: DynalistDocument, nodeId: string) {
  if (nodeId === "root") {
    throw new Error("Can't delete root");
  }
  const node = document.getNode(nodeId);
  if (!node) {
    throw new Error("Nonexistent node");
  }
  const indexBefore = node.parent!.children.indexOf(node);
  const trashId = getOrCreateTrash(document);
  document.move(nodeId, trashId, -1);

  return getAdviceForNextFocus(document, node, indexBefore);
}

export function TrashButton({
  document,
  nodeId,
  nextFocusAdviceCallback,
}: {
  document: DynalistDocument;
  nodeId: string;
  nextFocusAdviceCallback(nextFocusNodeId: string): void;
}) {
  return (
    <InlineIconButton
      ariaLabel="Trash"
      onClick={() => {
        nextFocusAdviceCallback(deleteAndAdviseNextFocus(document, nodeId));
      }}
    >
      <BiTrash />
    </InlineIconButton>
  );
}

import { useEffect, useRef } from "react";

import { DynalistDocument } from "../../service/dynalist/document/DynalistDocument";

let unique = 1;

export class FixedNodeIdStore {
  private fixedNodeIds = new Map<string, string>();

  getFixedNodeKey(nodeId: string) {
    if (!this.fixedNodeIds.has(nodeId)) {
      this.fixedNodeIds.set(nodeId, `fixednodekey-${unique++}`);
    }

    return this.fixedNodeIds.get(nodeId);
  }

  updatePermanentIds(permanentIds: { temporaryId: string; permanentId: string }[]) {
    for (const { temporaryId, permanentId } of permanentIds) {
      if (this.fixedNodeIds.has(temporaryId)) {
        const preassignedFixedNodeKey = this.fixedNodeIds.get(temporaryId);
        if (!preassignedFixedNodeKey) {
          throw new Error("Something is really wrong");
        }
        this.fixedNodeIds.set(permanentId, preassignedFixedNodeKey);
      }
    }
  }
}

export function useFixedNodeMapKeys(document?: DynalistDocument) {
  const fixedNodeIdStore = useRef(new FixedNodeIdStore());

  useEffect(() => {
    if (!document) {
      return;
    }

    function permanentIdListener(permanentIds: { temporaryId: string; permanentId: string }[]) {
      fixedNodeIdStore.current.updatePermanentIds(permanentIds);
    }

    document.addPermanentIdListener(permanentIdListener);

    return () => {
      document.removePermanentIdListener(permanentIdListener);
    };
  }, [document]);

  return fixedNodeIdStore.current;
}

import React from "react";
import { ListGroup } from "react-bootstrap";

import { useFixedNodeMapKeys } from "./useFixedNodeMapKeys";
import { ToolboxRevealOnHover } from "./ToolboxRevealOnHover";
import { DynalistDocument, TreeNode } from "../../service/dynalist/document/DynalistDocument";
import { ToolBox } from "./ToolBox";
import { NodeAction, TooltipAndActionNodeContent } from "./TooltipAndActionNodeContent";

function SubTree({
  document,
  nodes,
  depth,
  action,
}: {
  document: DynalistDocument;
  nodes: TreeNode[];
  depth: number;
  action?: NodeAction;
}) {
  const fixedNodeMapKeys = useFixedNodeMapKeys(document);

  return (
    <>
      {nodes.map((node) => (
        <React.Fragment key={fixedNodeMapKeys.getFixedNodeKey(node.id)}>
          <ListGroup.Item>
            <div style={{ paddingLeft: depth * 1.5 + "em" }}>
              <ToolboxRevealOnHover toolbox={<ToolBox document={document} nodeId={node.id} />}>
                <TooltipAndActionNodeContent node={node} document={document} action={action} showChildrenIndicator />
              </ToolboxRevealOnHover>
            </div>
          </ListGroup.Item>
          {node.children.length > 0 && (
            <SubTree document={document} nodes={node.children} depth={depth + 1} action={action} />
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export function ListGroupNodeTree({
  document,
  nodes,
  action,
}: {
  document: DynalistDocument;
  nodes: TreeNode[];
  action?: NodeAction;
}) {
  return (
    <ListGroup variant="flush">
      <SubTree document={document} nodes={nodes} depth={0} action={action} />
    </ListGroup>
  );
}

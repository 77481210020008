import { Button, Col, Row } from "react-bootstrap";
import React, { useState } from "react";
import { useAnimate } from "react-simple-animate";

import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { cloneDeep, flattenDepthFirst } from "../../../service/dynalist/nodes";
import { containsDynalistLink, extractDynalistLink } from "../../../service/dynalist/client/DynalistUrl";
import { useFixedNodeMapKeys } from "../../component/useFixedNodeMapKeys";
import { NodeContent } from "../../component/NodeContent";

import "./PlanPage.scss";

const includeLinkedProjectContents = false;

function withoutCheckedNodes(node: TreeNode) {
  const onlyNonChecked = cloneDeep(node);

  function ditchChecked(node: TreeNode) {
    node.children = node.children.filter((n) => !n.checked);
    node.children.forEach(ditchChecked);
  }

  ditchChecked(onlyNonChecked);

  return onlyNonChecked;
}

function includeProjectTasks(node: TreeNode, n: number, document: DynalistDocument) {
  const nodeWithProjectTasks = cloneDeep(node);

  function recurseIncludeProjectTasks(node: TreeNode) {
    if (includeLinkedProjectContents && containsDynalistLink(node.content)) {
      const { url, documentId, nodeId } = extractDynalistLink(node.content);
      const referencedNode = document.getNode(nodeId);
      if (!referencedNode) {
        return;
      }
      node.content = node.content.replace(url, referencedNode.content);
      if (documentId !== document.getDocumentId()) {
        throw new Error("Only linking within same document implemented");
      }
      const candidates = referencedNode.children.filter((c) => !c.checked);
      const indexOfSlashes = candidates.findIndex((c) => c.content.startsWith("--"));
      const endIndex = indexOfSlashes === -1 ? n : Math.min(n, indexOfSlashes);
      const childrenToInclude = (node.children = candidates.slice(0, endIndex));

      childrenToInclude.forEach((child) => (child.parent = node));
      node.children = childrenToInclude;
    } else {
      node.children.forEach(recurseIncludeProjectTasks);
    }
  }

  recurseIncludeProjectTasks(nodeWithProjectTasks);

  return nodeWithProjectTasks;
}

export function present(node: TreeNode, document: DynalistDocument) {
  node = includeProjectTasks(node, 2, document);
  node = withoutCheckedNodes(node);

  return node;
}

const animationDurationSec = 0.2;

function Node({ document, node }: { document: DynalistDocument; node: TreeNode; rootNode: TreeNode }) {
  const fixedNodeMapKeys = useFixedNodeMapKeys(document);

  const [isFocus, setIsFocus] = useState(false);

  const { play: playAnimation, style: animationStyle } = useAnimate({
    start: { transform: "translateX(0px)" },
    end: { transform: "translateX(100vw)" },
    duration: animationDurationSec,
  });

  if (node.content.startsWith("--")) {
    return <hr style={{ height: "6px", width: "90%", marginLeft: "5%" }} className="mt-2 mb-4" />;
  }

  return (
    <div
      className={`plan-page__node ${node.children.length > 0 ? "plan-page__node--has-subtasks" : ""}`}
      style={{
        display: "block",
        border: "0.5px solid #0007",
        borderRadius: "0.35em",
        ...(isFocus && {
          cursor: "pointer",
        }),
        ...animationStyle,
      }}
      onMouseOver={() => {
        setIsFocus(true);
      }}
      onMouseLeave={() => {
        setIsFocus(false);
      }}
      onClick={(e) => {
        e.stopPropagation();
        playAnimation(true);
        setTimeout(() => {
          document.edit(node.id, { checked: true });
        }, animationDurationSec * 1000);
      }}
    >
      <div>
        <NodeContent node={node} document={document} />
      </div>
      {node.children.slice(0, 60).map((n) => (
        <Node key={fixedNodeMapKeys.getFixedNodeKey(n.id)} document={document} node={n} rootNode={node} />
      ))}
    </div>
  );
}

export function PlanPage({ document, node }: { document: DynalistDocument; node: TreeNode }) {
  const fixedNodeMapKeys = useFixedNodeMapKeys(document);

  const cleanedNode = present(node, document);

  function reset(e: any) {
    (e as any).currentTarget.blur();
    flattenDepthFirst(node).forEach((node) => {
      if (node.checked) {
        document.edit(node.id, { checked: false });
      }
    });
  }

  const checkedItemsCount = flattenDepthFirst(node).filter((node) => node.checked).length;

  return (
    <>
      <Row>
        <Col className="mb-3">
          <NodeContent node={cleanedNode} document={document} />
        </Col>
      </Row>
      <Row>
        {cleanedNode.children.slice(0, 60).map((n) => (
          <Node key={fixedNodeMapKeys.getFixedNodeKey(n.id)} document={document} node={n} rootNode={cleanedNode} />
        ))}
      </Row>
      {checkedItemsCount > 0 && (
        <Row>
          <Button onClick={reset} variant="link">
            Reset checked items ({checkedItemsCount})
          </Button>
        </Row>
      )}
    </>
  );
}

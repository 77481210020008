// to overcome bug https://github.com/remix-run/react-router/issues/7460
import React, { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ListGroup } from "react-bootstrap";

export function ListGroupItemLink({ to, children }: { to: string; children: ReactNode }) {
  const navigate = useNavigate();

  return (
    <ListGroup.Item
      action
      to={to}
      as={Link}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setTimeout(() => {
          navigate(to);
        }, 0);
      }}
    >
      {children}
    </ListGroup.Item>
  );
}

import React from "react";
import moment from "moment";

import { DynalistDocument, TreeNode } from "../../../service/dynalist/document/DynalistDocument";
import { parseAndSort } from "../../../service/trackers";
import { NodeTree } from "../../component/NodeTree";
import { removeTimestampFromStart } from "../../../service/dateandtime";

export function CalendarViewPage({ document, node }: { document: DynalistDocument; node: TreeNode }) {
  const entries = parseAndSort(node.children);

  function removeTimestamps(nodeId: string) {
    const node = document.getNode(nodeId);
    if (!node) {
      throw new Error("Node not found");
    }

    return {
      ...node,
      content: removeTimestampFromStart(node.content),
    };
  }

  return (
    <>
      <p>{node.content}</p>
      {entries.map((e, i) => (
        <div key={i}>
          <p>
            {moment(e.date).format("dddd D.M.YYYY HH:mm")}
            {i < 5 && <span className="text-secondary ms-3">{moment(e.date).fromNow()}</span>}
          </p>
          <NodeTree document={document} nodes={[removeTimestamps(e.nodeId)]} />
        </div>
      ))}
    </>
  );
}

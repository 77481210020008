import { Col, Row } from "react-bootstrap";
import React, { ReactNode } from "react";

export function ResponsiveLeftSqueezeRightScrollableLayout({ left, right }: { left: ReactNode; right: ReactNode }) {
  return (
    <Row>
      <Col xs={12} sm={12} md={5} lg={4} xl={3} xxl={2} style={{ paddingBottom: "130px" }}>
        {left}
      </Col>
      <Col style={{ maxHeight: "100vh", overflowY: "scroll" }}>{right}</Col>
    </Row>
  );
}

import { DynalistClient } from "../client/DynalistClient";
import { DynalistDocument } from "../document/DynalistDocument";

export interface File {
  id: string;
  title: string;
}

function presentFile(file: any): File {
  return {
    id: file.id,
    title: file.title,
  };
}

// TODO: rename to CachedDynalistDocumentsService
export class DynalistDocumentStorage {
  private readonly cachedDocuments: { [documentId: string]: DynalistDocument };

  constructor(private dynalistClient: DynalistClient) {
    this.cachedDocuments = {};
  }

  async getDocuments(): Promise<File[]> {
    const response = await this.dynalistClient.listFiles();

    return response.files.filter((f: any) => f.type === "document").map(presentFile);
  }

  async getDocument(documentId: string): Promise<DynalistDocument> {
    if (!(documentId in this.cachedDocuments)) {
      const document = await this.dynalistClient.readDocument(documentId);
      this.cachedDocuments[documentId] = new DynalistDocument(documentId, document.nodes, this.dynalistClient);
    }

    return this.cachedDocuments[documentId];
  }
}

import React, { useState } from "react";
import { BiLeftArrowAlt, BiRightArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import { Col, Row } from "react-bootstrap";

import { Month, monthToMoment } from "./Month";
import { BujoMonth, getBujoMonthDays } from "./bujoMonthsService";
import { Day, dayToMoment } from "./Day";
import { DynalistDocument } from "../../../service/dynalist/document/DynalistDocument";
import { EditableNodeContent } from "./EditableNodeContent";

import "./BujoMonth.scss";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function stripDate(content: string): string {
  return content.replace(/^[0-9]{4}-[0-9]{2}-[0-9]{2} /, "");
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function addDate(content: string, day: Day): string {
  const m = dayToMoment(day);

  return `${m.format("YYYY-MM-DD")} ${content}`;
}

function BujoText({ document, day }: { document: DynalistDocument; day: Day }) {
  const [shouldOpenInEditMode, setShouldOpenInEditMode] = useState(false);

  function stripDate(content: string): string {
    return content.replace(/^[0-9]{4}-[0-9]{2}-[0-9]{2} /, "");
  }

  function addDate(content: string): string {
    const m = dayToMoment(day);

    return `${m.format("YYYY-MM-DD")} ${content}`;
  }

  if (day.node) {
    return (
      <EditableNodeContent
        className="bujo-month__text"
        document={document}
        node={day.node}
        beforeRender={stripDate}
        beforeSave={addDate}
        openInEditMode={shouldOpenInEditMode}
      />
    );
  } else {
    return (
      <div
        onClick={() => {
          document.insert(addDate(""), day.bujoMonth.nodeId, -1);
          setShouldOpenInEditMode(true);
        }}
        className="bujo-month__text"
        style={{ cursor: "text" }}
      />
    );
  }
}

function BujoDay({ document, day }: { document: DynalistDocument; day: Day }) {
  const m = dayToMoment(day);

  return (
    <div className="bujo-month__day">
      <div
        className={`bujo-month__day-number ${m.isoWeekday() === 1 ? "bujo-month__day-number--first-day-of-week" : ""}`}
      >
        {m.date()}
      </div>
      <div
        className={`bujo-month__day-of-week ${
          m.isoWeekday() === 1 ? "bujo-month__day-of-week--first-day-of-week" : ""
        }`}
      >
        {m.format("dd").substring(0, 1)}
      </div>
      <BujoText document={document} day={day} />
    </div>
  );
}

export function ViewBujoMonth({
  document,
  bujoMonth,
  month,
  left,
  up,
  right,
}: {
  document: DynalistDocument;
  bujoMonth: BujoMonth;
  month: Month;
  left(): void;
  up(): void;
  right(): void;
}) {
  const days = getBujoMonthDays(document, bujoMonth, month);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <span className="bujo-month__heading">
            {monthToMoment(month).format("MMMM YYYY")} {bujoMonth.name}
          </span>
        </Col>
        <Col xs="auto">
          <BiLeftArrowAlt onClick={left} />
        </Col>
        <Col xs="auto">
          <BiUpArrowAlt onClick={up} />
        </Col>
        <Col xs="auto">
          <BiRightArrowAlt onClick={right} />
        </Col>
      </Row>
      <Row>
        <Col>
          {days.map((day) => (
            <BujoDay key={day.day} document={document} day={day} />
          ))}
        </Col>
      </Row>
    </>
  );
}
